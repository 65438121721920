import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/system";

const ButtonSize = styled(Button)(({theme})=>({
  width: "100%",
  height: 30,
  padding: "0px 20px",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  border: "2px solid #252525",
  borderRadius: 10,
  display: "inline-block",
  backgroundColor: theme.palette.background.paper,
  transition: "background-color 0.3s, color 0.3s",
  "&:hover": {
    border: "2px solid #536976",
    backgroundColor: "#536976",
    color: "#FFFFFF",
  },
}));

export const ButtonCustom = ({
  margin,
  display = "inline-flex",
  active,
  onClick,
  label,
  ...props
}) => {
  return (
    <Box>
      <ButtonSize
        sx={{
          marginRight: margin,
          display: display,
          color: active ? "#FFFFFF" : "#252525",
          backgroundColor: active ? "#252525" : "#FFFFFF",
        }}
        onClick={onClick}
        {...props}
      >
        <Typography>{label}</Typography>
      </ButtonSize>
    </Box>
  );
};
