import { styled } from "@mui/material/styles";

export const Image = ({
  src,
  alt,
  width,
  height,
  objectFit = "contain",
  boxShadow,
  margin,
  ...props
}) => {
  const RenderImage = styled("img")({
    width: width,
    height: height,
    objectFit: `${objectFit} !important`,
    boxShadow: boxShadow && "0 4px 8px rgba(0, 0, 0, 0.5)",
    margin: margin,
  });
  return <RenderImage src={src} alt={alt} {...props} />;
};
