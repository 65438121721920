import { createSlice } from "@reduxjs/toolkit";

const loginSlice = createSlice({
  name: "login",
  initialState: {
    isLoading: false,
    email: "",
    password: "",
  },
  reducers: {
    setIsLoading_login: (state, { payload }) => {
      state.isLoading = payload;
    },
    setEmail: (state, { payload }) => {
      state.email = payload;
    },
    setPassword: (state, { payload }) => {
      state.password = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setIsLoading_login, setEmail, setPassword } = loginSlice.actions;

export const loginReducer = loginSlice.reducer;
