import React from "react";
import { AuthWrapper } from "../../../wrapper";
import { LoginTemplate } from "../../../Template";

export const Login = () => {
  return (
    <AuthWrapper>
      <LoginTemplate />
    </AuthWrapper>
  );
};
