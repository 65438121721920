//IMPORT ALL MAIN PAGES
import {
  Home,
  ProductDetails,
  AddToCart,
  Buynow,
  EarnWithUs,
  ContactUs,
  Genre,
  TrackOrder,
  PrivacyPolicies,
  RefundPolicies,
  ShippingPolicies,
  TermAndCondition
} from "../components/pages";

//IMPORT ALL AUTH PAGES
import { Register, Login } from "../components/pages";

//IMPORT ALL ADMIN PAGES
import { PostCreate, AllPostList } from "../components/pages";

export const route = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "productdetails/:id",
    element: <ProductDetails />,
  },
  {
    path: "register",
    element: <Register />,
  },
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "addtocart",
    element: <AddToCart />,
  },
  {
    path: "buynow",
    element: <Buynow />,
  },
  {
    path: "earnwithus",
    element: <EarnWithUs />,
  },
  {
    path: "contactus",
    element: <ContactUs />,
  },
  {
    path: "genre/:id",
    element: <Genre />,
  },
  {
    path: "trackorder",
    element: <TrackOrder />,
  }, 
  {
    path: "privacypolicies",
    element: <PrivacyPolicies />,
  },
  {
    path: "refundpolicies",
    element: <RefundPolicies />,
  },
  {
    path: "shippingpolicies",
    element: <ShippingPolicies />,
  },  {
    path: "termcondition",
    element: <TermAndCondition />,
  },
  
];

export const adminRoutes = [
  {
    path: "postcreate",
    element: <PostCreate />,
  },
  {
    path: "allpostlist",
    element: <AllPostList />,
  },
];
