import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import {
  CartProductDetailsContainerStyle,
  itemBoxStyle,
  itemBoxStyleSM,
} from "../../../utils/commonStyles";

const CartProductDetailsContainer = styled(Box)(({ theme }) => ({
  ...CartProductDetailsContainerStyle({ theme }),
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    alignItems: "flex-start",
  },
}));

const ItemBoxHeader = styled(Box)(({ theme, hideOnSmall }) => ({
  ...itemBoxStyle,
  height: "50px",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    ...itemBoxStyleSM,
    display: hideOnSmall ? "none" : "flex", // Hide specific itemBoxHeader on small screens
  },
}));

export const CartItemDetailsHeader = () => {
  return (
    <CartProductDetailsContainer>
      <ItemBoxHeader>
        <Typography variant="h4">Product</Typography>
      </ItemBoxHeader>
      <ItemBoxHeader hideOnSmall>
        <Typography variant="h4">Unit Price</Typography>
      </ItemBoxHeader>
      <ItemBoxHeader hideOnSmall>
        <Typography variant="h4">Quantity</Typography>
      </ItemBoxHeader>
      <ItemBoxHeader hideOnSmall>
        <Typography variant="h4">Total</Typography>
      </ItemBoxHeader>
      <ItemBoxHeader hideOnSmall>
        <Typography variant="h4"></Typography>
      </ItemBoxHeader>
    </CartProductDetailsContainer>
  );
};
