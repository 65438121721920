import React from "react";

import { Box } from "@mui/material";
import { styled } from "@mui/system";
import { ButtonCustom, LoadingIndicator } from "../../atoms";

const FormActionsWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    display: "block",
  },
}));

const ActionLinks = styled(Box)(({ theme }) => ({
  width: "50%",
  display: "flex",
  flexDirection: "column",
  "& > *": {
    margin: "10px 0",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const LoginButtonContainer = styled(Box)(({ theme }) => ({
  width: "50%",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

export const FormActions = ({
  label,
  onClick,
  children,
  loading,
  ...props
}) => {
  return (
    <FormActionsWrapper>
      <ActionLinks>{children}</ActionLinks>
      <LoginButtonContainer>
        {loading ? (
          <LoadingIndicator />
        ) : (
          <ButtonCustom label={label} onClick={onClick} {...props} />
        )}
      </LoginButtonContainer>
    </FormActionsWrapper>
  );
};
