import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  styled,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  canvas,
  eco,
  hang,
  india,
  quality,
  scale,
  WoodenFrames,
} from "../../../../assets";

const AccordionContainer = styled(Box)(({ theme }) => ({
  width: "80%",
  margin: "10px auto",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const AccordionStyled = styled(Accordion)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const AccordionDetailsStyled = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const ListItemBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "5px",
  margin: "15px 0px",
});

const ListItemBoxForDetails = styled(Box)({
  margin: "15px 0px",
});

const IconImage = styled("img")({
  width: "25px",
  height: "25px",
});

export const ProductDetailsAccordion = () => {
  const [expanded, setExpanded] = useState(false);
  const [nestedExpanded, setNestedExpanded] = useState(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleNestedAccordionChange = (panel) => (event, isExpanded) => {
    setNestedExpanded(isExpanded ? panel : false);
  };

  return (
    <AccordionContainer>
      {/* Product Specifications */}
      <AccordionStyled
        expanded={expanded === "panel1"}
        onChange={handleAccordionChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography variant="h4">Product Specifications</Typography>
        </AccordionSummary>

        <AccordionDetailsStyled>
          {/* Nested Accordion For Canvas*/}
          <AccordionStyled
            expanded={nestedExpanded === "nestedPanel1"}
            onChange={handleNestedAccordionChange("nestedPanel1")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="nestedPanel1-content"
              id="nestedPanel1-header"
            >
              <Typography variant="h5">For Canvas</Typography>
            </AccordionSummary>

            <AccordionDetailsStyled>
              <ListItemBox>
                <Typography variant="h6">
                  - All measurements are provided in inches.
                </Typography>
                <IconImage src={scale} alt="scale icon" />
              </ListItemBox>

              <ListItemBox>
                <Typography variant="h6">
                  - Pre-Hung for Immediate Display
                </Typography>
                <IconImage src={hang} alt="hang icon" />
              </ListItemBox>

              <ListItemBox>
                <Typography variant="h6">
                  - Crafted by Hand in India
                </Typography>
                <IconImage src={india} alt="india icon" />
              </ListItemBox>

              <ListItemBox>
                <Typography variant="h6">
                  - Eco-Conscious with High-Resolution Print
                </Typography>
                <IconImage src={eco} alt="eco icon" />
              </ListItemBox>

              <ListItemBox>
                <Typography variant="h6">
                  - Sturdy Gallery-Quality Wooden Frames
                </Typography>
                <IconImage src={WoodenFrames} alt="wooden frames icon" />
              </ListItemBox>

              <ListItemBox>
                <Typography variant="h6">
                  - Canvas Coating that Resists Damage
                </Typography>
                <IconImage src={canvas} alt="canvas icon" />
              </ListItemBox>
            </AccordionDetailsStyled>
          </AccordionStyled>

          {/* Nested Accordion For Poster*/}
          <AccordionStyled
            expanded={nestedExpanded === "nestedPanel2"}
            onChange={handleNestedAccordionChange("nestedPanel2")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="nestedPanel2-content"
              id="nestedPanel2-header"
            >
              <Typography variant="h5">For Poster</Typography>
            </AccordionSummary>
            <AccordionDetailsStyled>
              <ListItemBox>
                <Typography variant="h6">
                  - Available Sizes: A4, A3, 12x18 inches—perfect for any space.
                </Typography>
                <IconImage src={scale} alt="scale icon" />
              </ListItemBox>

              <ListItemBox>
                <Typography variant="h6">
                  - Quality: Premium matte finish—where quality and versatility
                  come together.
                </Typography>
                <IconImage src={quality} alt="hang icon" />
              </ListItemBox>

              <ListItemBox>
                <Typography variant="h6">
                  - Crafted by Hand in India
                </Typography>
                <IconImage src={india} alt="india icon" />
              </ListItemBox>

              <ListItemBox>
                <Typography variant="h6">
                  - Eco-Conscious with High-Resolution Print
                </Typography>
                <IconImage src={eco} alt="eco icon" />
              </ListItemBox>

              <ListItemBox>
                <Typography variant="h6">
                  - Note: Frames are not included. Our posters are designed for
                  easy framing, letting you pick a frame that best suits your
                  style and decor.
                </Typography>
                <IconImage src={WoodenFrames} alt="wooden frames icon" />
              </ListItemBox>
            </AccordionDetailsStyled>
          </AccordionStyled>
        </AccordionDetailsStyled>
      </AccordionStyled>

      {/* Shipping details */}
      <AccordionStyled
        expanded={expanded === "panel2"}
        onChange={handleAccordionChange("panel2")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography variant="h4">Shipping details</Typography>
        </AccordionSummary>
        <AccordionDetailsStyled>
          <ListItemBoxForDetails>
            <Typography variant="h4" fontWeight={"500"} margin>
              Delivery Time
            </Typography>
            <Typography variant="h6" margin>
              Orders typically arrive within 4 to 9 days. Delivery times may
              vary; we appreciate your patience during this period.
            </Typography>
          </ListItemBoxForDetails>

          <ListItemBoxForDetails>
            <Typography variant="h4" fontWeight={"500"} margin>
              Shipping
            </Typography>
            <Typography variant="h6" margin>
              Free shipping is included on all orders. No extra fees for
              delivery, ensuring a smooth and cost-free shopping experience.
            </Typography>
          </ListItemBoxForDetails>

          <ListItemBoxForDetails>
            <Typography variant="h4" fontWeight={"500"} margin>
              Printing Process
            </Typography>
            <Typography variant="h6" margin>
              All items are printed on demand. We don’t pre-print or stock,
              allowing for custom, fresh products tailored just for you.
            </Typography>
          </ListItemBoxForDetails>

          <ListItemBoxForDetails>
            <Typography variant="h4" fontWeight={"500"} margin>
              Inventory
            </Typography>
            <Typography variant="h6" margin>
              Expect a slight delay due to our print-on-demand process. We don’t
              hold inventory, so your patience is appreciated.
            </Typography>
          </ListItemBoxForDetails>

          <ListItemBoxForDetails>
            <Typography variant="h4" fontWeight={"500"} margin>
              Order Processing
            </Typography>
            <Typography variant="h6" margin>
              Most orders are delivered within 4 to 9 days. Timely processing
              and shipping are our priorities. Thank you for your patience.
            </Typography>
          </ListItemBoxForDetails>
        </AccordionDetailsStyled>
      </AccordionStyled>
    </AccordionContainer>
  );
};
