import React from "react";
import { HomeTemplate } from "../../../Template";
import { Footer, Navbar } from "../../../organisms";
import { ScrollOnTop } from "../../../wrapper";

export const Home = () => {
  return (
    <ScrollOnTop>
      <Navbar />
      <HomeTemplate />
      <Footer />
    </ScrollOnTop>
  );
};
