import React from "react";
import { ProductDetailsTemplate } from "../../../Template";
import { Navbar, Footer } from "../../../organisms";
import {ScrollOnTop} from '../../../wrapper'


export const ProductDetails = () => {
  return (
    <ScrollOnTop>
      <Navbar />
      <ProductDetailsTemplate />
      <Footer />
    </ScrollOnTop>
  );
};
