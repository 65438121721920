export const itemBoxStyle = {
  width: "20%",
  display: "flex",
  alignItems: "center",
};

export const itemBoxStyleSM = {
  width: "100%",
  justifyContent: "center",
};

export const CartProductDetailsContainerStyle = ({ theme }) => ({
  width: "80%",
  margin: "50px auto",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexDirection: "row",
  [theme.breakpoints.down("sm")]: {
    width: "90%",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  [theme.breakpoints.down("xs")]: {
    width: "100%",
    flexDirection: "column",
    alignItems: "flex-start",
  },
});
