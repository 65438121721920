import { createSlice } from "@reduxjs/toolkit";

const allPostSlice = createSlice({
  name: "all post",
  initialState: {
    isLoading: true,
    allPost: [],
  },
  reducers: {
    setIsLoading_AllPost: (state, { payload }) => {
      state.isLoading = payload;
    },
    setAllPost: (state, { payload }) => {
      state.allPost = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setIsLoading_AllPost, setAllPost } = allPostSlice.actions;

export const allPostReducer = allPostSlice.reducer;
