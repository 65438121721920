import React from "react";
import { styled } from "@mui/system";
import { Box, Typography } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SliderContainer = styled(Box)({
  width: "100%",
  height: "80%",
  boxSizing: "border-box",
  overflow: "hidden",
  position: "relative",
  ".slick-dots": {
    position: "absolute",
    bottom: 10,
    left: "50%",
    transform: "translateX(-50%)",
    "& li button": {
      backgroundColor: "white",
      width: "10px",
      height: "10px",
      borderRadius: "50%",
      padding: "0px",
      margin: "0px",
    },
    "& li.slick-active button": {
      backgroundColor: "white",
    },
  },
  ".slick-dots.slick-thumb": {
    display: "flex !important",
    justifyContent: "center",
    padding: "0",
    listStyle: "none",

    li: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: "0 20px !important",
    },
  },
});

const ImageContainer = styled(Box)({
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  boxSizing: "border-box",
  padding: "0px",
});

const ImageStyled = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "cover",
});

const HeadingContainer = styled(Typography)({
  width: "80%",
  margin: "20px auto",
});

export const DropingCarousel = ({ data, label }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    swipe: true,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 1,
          dots: false,
        },
      },
    ],
  };

  return (
    <SliderContainer>
      <HeadingContainer variant="h2" fontWeight={"300"}>
        {label}
      </HeadingContainer>
      <Slider {...settings}>
        {data.map((image, index) => (
          <ImageContainer key={index}>
            <ImageStyled src={image?.thumbnail} alt={image.name} />
          </ImageContainer>
        ))}
      </Slider>
    </SliderContainer>
  );
};
