import React from "react";
import { Box, TextField } from "@mui/material";
import { styled } from "@mui/system";
import { Form, FormActions } from "../../../organisms";
import { useNavigate } from "react-router-dom";

const StyledForm = styled(Box)({
  width: "100%",
});

export const RegisterTemplate = () => {
  const navigate = useNavigate();

  return (
    <Form label={"Sign up"}>
      <StyledForm component="form">
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Full name"
          name="Full name"
          autoFocus
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Email"
          placeholder="Email"
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Phone no"
          name="Phone no"
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
        />
        <FormActions onClick={() => {}} label={"Sign up"}>
          <Box onClick={()=>{navigate("/login")}} style={{cursor:"pointer"}}>Login</Box>
        </FormActions>
      </StyledForm>
    </Form>
  );
};
