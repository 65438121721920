import React from "react";
import { Rating } from "@mui/material";

export const RatingCustom = ({
  namne,
  readOnly,
  value,
  color,
  onChange,
  ...props
}) => {
  return (
    <Rating
      name={namne}
      value={value}
      readOnly={readOnly}
      sx={{
        "& .MuiRating-iconFilled": {
          color: color,
        },
        "& .MuiRating-iconHover": {
          color: color,
        },
        "& .MuiRating-iconEmpty": {
          color: color,
        },
      }}
      onChange={onChange}
      {...props}
    />
  );
};
