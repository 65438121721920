import React from "react";
import { AuthWrapper } from "../../../wrapper";
import { RegisterTemplate } from "../../../Template";

export const Register = () => {
  return (
    <AuthWrapper>
      <RegisterTemplate />
    </AuthWrapper>
  );
};
