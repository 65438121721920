import React, { useEffect } from "react";
import { DropingCarousel } from "../../../organisms";
import { CategoryProduct } from "../../../organisms/CategorysProduct";
import { Banner, FullViewComponent } from "../../../molicules";
import { main } from "../../../../utils/api/apiCall";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  setHomePageData,
  setIsLoading_Home,
} from "../../../../redux/reducer/main";
import { space } from "../../../../assets";

export const HomeTemplate = () => {
  // Redux state
  const dispatch = useDispatch();
  const { isLoading, homePageData } = useSelector(
    (state) => state.homePageDetails
  );

  const {
    bannerImageThree,
    horizontalDesigns,
    canvasDesigns,
    posterDesigns,
    bestSales,
  } = homePageData;

  const fetchHomePageData = async () => {
    dispatch(setIsLoading_Home(true));
    try {
      let response = await main.homePageApi();
      if (response.success) {
        const data = {
          bannerImageOne: response.result.bannerImages.banner_one,
          bannerImageThree: response.result.bannerImages.banner_three,
          horizontalDesigns: response.result.horizontal,
          canvasDesigns: response.result.canvasDesign,
          posterDesigns: response.result.posterDesign,
          bestSales: response.result.bestSales,
        };
        dispatch(setHomePageData(data));
      } else {
        console.error("Error fetching home page data:", response.message);
      }
    } catch (error) {
      console.error("Network error:", error);
    } finally {
      dispatch(setIsLoading_Home(false));
    }
  };

  useEffect(() => {
    fetchHomePageData();
  }, [dispatch]);

  return (
    <>
      {isLoading ? (
        <FullViewComponent />
      ) : (
        <>
          <DropingCarousel data={horizontalDesigns} />
          <CategoryProduct productData={bestSales} label={"Best Sales"} />
          <Banner src={space} alt="Space Banner" />
          <CategoryProduct productData={canvasDesigns} label={"Canvas"} />
          <Banner src={bannerImageThree} alt="Banner Three" />
          <CategoryProduct productData={posterDesigns} label={"Posters"} />
        </>
      )}
    </>
  );
};
