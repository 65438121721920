import { Box, styled } from "@mui/material";
import React from "react";

const ImageWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "100%",
  height: "500px",
  overflow: "hidden",
  [theme.breakpoints.down("md")]: {
    height: "400px",
  },
  [theme.breakpoints.down("sm")]: {
    height: "300px",
  },
}));

const BaseImage = styled("img")({
  width: "100%",
  height: "100%",
  display: "block",
});

const OverlayVerticalImage = styled("img")({
  position: "absolute",
  maxWidth: "100%",
  maxHeight: "100%",
  boxShadow: "15px 15px 6px -5px rgba(0,0,0,0.3)",
});

export const OverLayMockup = ({
  index,
  mockupImage,
  OverlayElement,
  image,
  height
}) => {
  return (
    <ImageWrapper key={index} style={{height}}>
      <BaseImage src={mockupImage} alt={mockupImage} />
      <OverlayVerticalImage
        style={{ ...OverlayElement }}
        src={image}
        alt={image}
      />
    </ImageWrapper>
  );
};
