import React from "react";
import { AuthWrapper } from "../../../wrapper";
import { ContactUsTemplate } from "../../../Template";

export const ContactUs = () => {
  return (
    <AuthWrapper>
      <ContactUsTemplate />
    </AuthWrapper>
  );
};
