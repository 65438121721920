import React from "react";
import { AllPostsListTemplate } from "../../../Template";
import { Footer, Navbar } from "../../../organisms";

export const AllPostList = () => {
  return (
    <>
      <Navbar />
      <AllPostsListTemplate />
      <Footer />
    </>
  );
};
