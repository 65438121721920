import React, { useEffect } from "react";
import { styled } from "@mui/system";
import { Box, Typography } from "@mui/material";
import {
  ButtonCustom,
  DiscountLabel,
  PriceWithDiscountLabel,
} from "../../../atoms";
import { useParams } from "react-router-dom";
import { FullViewComponent, SizeChart } from "../../../molicules";
import {
  ProductImageSlider,
  CategoryProduct,
  RatingContainer,
  OverLayMockup,
} from "../../../organisms";
import { main } from "../../../../utils/api/apiCall";
import { useSelector, useDispatch } from "react-redux";
import {
  setPaperType,
  setRotationType,
  setSizeChart,
  setOriginalPrice,
  setDiscountPrice,
  setActiveSize,
  setProductDetails,
  setIsLoading_productDetails,
  setDesignerDetails,
  setSimilarPost,
  setPaperTypeData,
} from "../../../../redux/reducer/main";
import { setNotification } from "../../../../redux/reducer/commonReducer";
import { setCartProduct } from "../../../../redux/reducer/main";
import { mockups } from "../../../../assets";
import { horizontalMockupData } from "../../../../utils/staticData";
import { ProductDetailsAccordion } from "./accordion";

// Styled components
const SellProductContainer = styled(Box)(({ theme }) => ({
  width: "80%",
  display: "flex",
  justifyContent: "space-between",
  margin: "50px auto",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "center",
  },
}));

const ProductDetailsContainer = styled(Box)(({ theme }) => ({
  width: "45%",
  boxSizing: "border-box",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const StyledBox = styled(Box)({
  width: "80%",
  margin: "10px auto",
  display: "flex",
  flexDirection: "column",
  gap: "20px",
});

export const ProductDetailsTemplate = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const {
    isLoading,
    paperType,
    paperTypeData,
    rotationType,
    sizeChart,
    originalPrice,
    discountedPrice,
    selectedSize,
    productItemDetails,
    designerDetails,
    similarPost,
  } = useSelector((state) => state.productDetails);

  const { cartProducts } = useSelector((state) => state.cartDetails);

  const handleSelectSize = (price, discountPrice, index) => {
    dispatch(setOriginalPrice(price));
    dispatch(setDiscountPrice(discountPrice));
    dispatch(setActiveSize(index));
  };

  const handlePaperType = async (type, id, rotationType) => {
    const { result } = await main.sizeList(id);
    const canvasHorizontalSizeList = result.filter(
      (item) => item.rotation === "horizontal"
    );
    const canvasVerticalSizeList = result.filter(
      (item) => item.rotation === "vertical"
    );

    const chart =
      type === "Poster"
        ? result
        : rotationType === "horizontal"
        ? canvasHorizontalSizeList
        : canvasVerticalSizeList;

    dispatch(setSizeChart(chart));
    dispatch(setOriginalPrice(chart[0].price));
    dispatch(setDiscountPrice(chart[0].discount));
    handleSelectSize(chart[0].price, chart[0].discount, 0);
    dispatch(setPaperType(type));
  };

  const fetchPostData = async () => {
    try {
      dispatch(setIsLoading_productDetails(true));
      const response = await main.postById(id);
      const printType =
        response.result.post.paperType === "Both"
          ? "Poster"
          : response.result.post.paperType === "Canvas"
          ? "Canvas"
          : "Poster";

      const printTypeID = response.result.printTypeData[0]._id;
      dispatch(setProductDetails(response.result.post));
      dispatch(setDesignerDetails(response.result.user));
      dispatch(setRotationType(response.result.post?.imageRotationType));
      dispatch(setPaperTypeData(response.result.printTypeData));

      await handlePaperType(
        printType,
        printTypeID,
        response.result.post?.imageRotationType
      );
      await fetchSimilarPost(response.result.post.genre);

      dispatch(setIsLoading_productDetails(false));
    } catch (error) {
      dispatch(
        setNotification({
          status: "error",
          message: error,
          visible: true,
        })
      );
    }
  };

  const fetchSimilarPost = async (genre) => {
    const response = await main.similarPost(genre);
    dispatch(setSimilarPost(response.result));
  };

  useEffect(() => {
    fetchPostData();
  }, [id]);

  // Add to cart
  const discountedPriced = () => {
    let quantityPrice = productItemDetails.image_quantity * discountedPrice;
    let percentValue = productItemDetails.image_quantity > 20 ? 32 : 20;
    let finalPrice = quantityPrice - (quantityPrice * percentValue) / 100;
    return Math.floor(finalPrice + 1);
  };

  const handleAddToCart = () => {
    const size = sizeChart[selectedSize]?.size;

    let isMulti = productItemDetails.isMultiple;
    let isDiscount = isMulti ? discountedPriced() : discountedPrice;

    const existingProductIndex = cartProducts.findIndex(
      (item) =>
        item.id === productItemDetails._id &&
        item.paperType === paperType &&
        item.size === size
    );

    let updatedCartProducts;

    if (existingProductIndex !== -1) {
      updatedCartProducts = cartProducts.map((item, index) =>
        index === existingProductIndex
          ? {
              ...item,
              quantity: item.quantity + 1,
              price: item.price + isDiscount,
            }
          : item
      );
    } else {
      const newProduct = {
        id: productItemDetails._id,
        quantity: 1,
        price: isDiscount,
        originalPrice: isMulti
          ? originalPrice * productItemDetails.image_quantity
          : originalPrice,
        discountedPrice: isDiscount,
        paperType,
        imageRotationType: productItemDetails.imageRotationType,
        image: productItemDetails.thumbnail,
        size,
        name: productItemDetails.name,
        designerID: designerDetails._id,
        designerName: designerDetails.fullName,
        isMultiple: productItemDetails.isMultiple,
        image_quantity: productItemDetails.image_quantity,
      };
      updatedCartProducts = [...cartProducts, newProduct];
    }
    dispatch(setCartProduct(updatedCartProducts));
  };

  return (
    <>
      {isLoading ? (
        <FullViewComponent />
      ) : (
        <>
          <SellProductContainer>
            <ProductDetailsContainer>
              {/* {productItemDetails.isMultiple ? (
                <Box
                  sx={{
                    width: "100%",
                    height: "500px",
                  }}
                >
                  <Image
                    src={productItemDetails.thumbnail}
                    width={"100%"}
                    height={"100%"}
                    boxShadow={true}
                  />
                </Box>
              ) : (
                <ProductImageSlider
                  rotationType={rotationType}
                  image={productItemDetails.thumbnail}
                  paperType={paperType}
                />
              )} */}
              <ProductImageSlider
                rotationType={rotationType}
                image={productItemDetails.thumbnail}
                paperType={paperType}
              />
            </ProductDetailsContainer>

            <ProductDetailsContainer>
              <StyledBox>
                <Typography variant="h5">{productItemDetails.name}</Typography>
                <Typography variant="h5">
                  Image Rotation type is {rotationType}
                </Typography>

                <SizeChart
                  paperType={paperType}
                  paperTypeData={paperTypeData}
                  sizeChartList={sizeChart}
                  handlePaperType={handlePaperType}
                  handleSelectSize={handleSelectSize}
                  activeSizeBTN={selectedSize}
                />

                <PriceWithDiscountLabel
                  originalPrice={
                    productItemDetails.isMultiple
                      ? productItemDetails.image_quantity * originalPrice
                      : originalPrice
                  }
                  discountedPrice={
                    productItemDetails.isMultiple
                      ? productItemDetails.image_quantity * discountedPrice
                      : discountedPrice
                  }
                />

                {productItemDetails.isMultiple && (
                  <DiscountLabel discountedPrice={discountedPriced()} />
                )}
                <ButtonCustom label="Add to cart" onClick={handleAddToCart} />
              </StyledBox>
            </ProductDetailsContainer>
          </SellProductContainer>

          <ProductDetailsAccordion />

          {paperType === "Canvas" && rotationType === "horizontal" && (
            <OverLayMockup
              index={0}
              mockupImage={mockups.horizontal.horizontal_3}
              OverlayElement={horizontalMockupData[2].OverlayElement}
              image={productItemDetails.thumbnail}
              height={"100%"}
            />
          )}

          <CategoryProduct
            productData={similarPost}
            label="You might like these suggestions."
          />

          <RatingContainer />
        </>
      )}
    </>
  );
};
