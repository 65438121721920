import { createSlice } from "@reduxjs/toolkit";

const userInfoSlice = createSlice({
  name: "user",
  initialState: {
    userInfo: {},
    userRole: "user",
  },
  reducers: {
    setUserInfo: (state, { payload }) => {
      state.userInfo = payload;
    },
    setRole: (state, { payload }) => {
      state.userRole = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUserInfo, setRole } = userInfoSlice.actions;

export const userInfoReducer = userInfoSlice.reducer;
