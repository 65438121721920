import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { ToastNotification } from "../../molicules";

const AuthContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "100vh",
  width: "100%",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

const LeftPane = styled(Box)(({ theme }) => ({
  width: "50%",
  backgroundColor: "#222",
  color: "#fff",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "30vh",
  },
}));

const RightPane = styled(Box)(({ theme }) => ({
  width: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    padding: "50px 0px"
  },
}));

export const AuthWrapper = ({ children }) => {
  return (
    <AuthContainer>
      <LeftPane>
        <Typography variant="h1" style={{ fontSize: "5rem" }}>
          क्रिंस
        </Typography>
      </LeftPane>
      <RightPane>{children}</RightPane>
      <ToastNotification open={true} close={() => {}} />
    </AuthContainer>
  );
};
