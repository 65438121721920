import React from "react";
import { ButtonCustom } from "../../atoms";
import { Box, styled, Typography } from "@mui/material";

const EmpityBasketContainer = styled(Box)({
  width: "80%",
  height: "100px",
  margin: "20px auto",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexDirection: "column",
});

const CartHeadingText = styled(Typography)({
  fontSize: "32px",
  fontFamily: "Inria Sans",
});

export const EmpityBasket = ({ onClick }) => {
  return (
    <EmpityBasketContainer>
      <CartHeadingText>Your Basket Is Empty</CartHeadingText>
      <ButtonCustom label={"Continue Shoping"} onClick={onClick} />
    </EmpityBasketContainer>
  );
};
