import { instance } from "./index";
import { endpoints } from "./endpoint";

export const getAllPost = () => {
  return instance.get(endpoints.admin.showAllPost);
};

export const createPost = (data) => {
  return instance.post(endpoints.admin.createPost, data);
};

export const uploadSingleMedia = (data) => {
  return instance.post(endpoints.admin.uploadSingleMedia, data, {
    headers: {
      "Content-Type": "multipart/form-data",
      redirect: "follow",
    },
  });
};

export const uploadMultipleMedia = (data) => {
  return instance.post(endpoints.admin.uploadmultiplMedia, data, {
    headers: {
      "Content-Type": "multipart/form-data",
      redirect: "follow",
    },
  });
};

export const auth = {
  login: (data) => {
    return instance.post(endpoints.auth.login, data);
  },
};

export const main = {
  homePageApi: () => {
    return instance.get(endpoints.main.homePage);
  },
  postById: (id) => {
    return instance.get(`${endpoints.main.postByID}/${id}`);
  },
  similarPost: (genre) => {
    return instance.get(`${endpoints.main.similarPost}/${genre}`);
  },
  createOrder: (data) => {
    return instance.post(endpoints.main.createOrder, data);
  },
  sizeList: (id) => {
    return instance.get(`${endpoints.main.sizeList}/${id}`);
  },
  genre: () => {
    return instance.get(endpoints.main.genre);
  },
  postByGenre: (genre) => {
    return instance.get(`${endpoints.main.postByGenre}/${genre}`);
  },
  orderById: (id) => {
    return instance.get(`${endpoints.main.order}/${id}`);
  },
  contactUs: (data) => {
    return instance.post(endpoints.main.contactUs, data);
  },
};
