import React, { useState, useRef } from "react";
import { styled } from "@mui/system";
import { IconButton } from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import DeleteIcon from "@mui/icons-material/Delete";

// Styled components
const PreviewImage = styled("img")({
  width: "100px",
  height: "100px",
  marginTop: "10px",
  marginRight: "10px",
  objectFit: "contain"
});

const ImagePreviewContainer = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  position: "relative",
});

const ImageContainer = styled("div")({
  position: "relative",
  marginRight: "10px",
  marginBottom: "10px", // Added marginBottom to avoid overlap
});

const DeleteButton = styled(IconButton)({
  position: "absolute",
  top: "5px", // Adjusted for better visibility
  right: "5px", // Adjusted for better visibility
  color: "#fff",
  backgroundColor: "rgba(0,0,0,0.5)",
  "&:hover": {
    backgroundColor: "rgba(0,0,0,0.7)",
  },
  zIndex: 1, // Ensure it appears above other elements
});

const FileInput = styled("input")({
  display: "none",
});

const FileLabel = styled("label")({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  marginTop: "10px",
  marginBottom: "10px",
});

export const ImageUpload = ({
  name,
  accept,
  multiple,
  onFileChange,
  filePreview,
  onDeleteImage,
}) => {
  const [previewUrls, setPreviewUrls] = useState(filePreview || []);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const { files } = event.target;
    const fileArray = Array.from(files);

    onFileChange(fileArray);
    setPreviewUrls(fileArray.map((file) => URL.createObjectURL(file)));

    event.target.value = null;
  };

  const handleImageDelete = (index) => {
    onDeleteImage(index);
    setPreviewUrls((prev) => {
      const newUrls = prev.filter((_, i) => i !== index);
      if (newUrls.length === 0) {
        fileInputRef.current.value = null;
      }
      return newUrls;
    });
  };

  return (
    <div>
      <FileLabel htmlFor={`${name}-file`}>
        <IconButton component="span">
          <UploadIcon />
        </IconButton>
        <FileInput
          id={`${name}-file`}
          type="file"
          name={name}
          accept={accept}
          multiple={multiple}
          onChange={handleFileChange}
          ref={fileInputRef}
        />
      </FileLabel>

      {previewUrls.length >= 1 && (
        <ImagePreviewContainer>
          {previewUrls.map((image, index) => (
            <ImageContainer key={index}>
              <PreviewImage src={image} alt={`Image Preview ${index}`} />
              {!filePreview.name && (
                <DeleteButton onClick={() => handleImageDelete(index)}>
                  <DeleteIcon />
                </DeleteButton>
              )}
            </ImageContainer>
          ))}
        </ImagePreviewContainer>
      )}
    </div>
  );
};
