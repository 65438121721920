import React from "react";
import { BuyNowTemplate } from "../../../Template/mainTemplate/buynowTemplate";
import { Footer, Navbar } from "../../../organisms";
import { ScrollOnTop } from "../../../wrapper";

export const Buynow = () => {
  return (
    <ScrollOnTop>
      <Navbar />
      <BuyNowTemplate />
      <Footer />
    </ScrollOnTop>
  );
};
