import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { ButtonCustom, InputField, LoadingIndicator } from "../../../atoms";
import { main } from "../../../../utils/api/apiCall";

// State imports
import {
  setIsLoading_Order,
  setOrderDetails,
  setOrderId,
} from "../../../../redux/reducer/main/orderReducer";
import { setNotification } from "../../../../redux/reducer/commonReducer";
import { useDispatch, useSelector } from "react-redux";
import { ProductImagesDetails } from "../../../molicules";
import moment from "moment";

// Define styled components
const StyledContainer = styled(Box)(({ theme }) => ({
  width: "80%",
  margin: "0 auto",
  minHeight: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  boxSizing: "border-box",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const FormContainer = styled(Box)(({ theme }) => ({
  width: "60%",
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {
    width: "90%",
  },
}));

const ProductsContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "90%",
  padding: "50px 30px",
  boxSizing: "border-box",
}));

const ProductItem = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  marginBottom: "20px",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
}));

const OrderDetailsBox = styled(Box)({
  borderRadius: "10px",
  overflow: "hidden",
  border: "2px solid #252525",
});

const OrderHeader = styled(Box)(({ theme }) => ({
  backgroundColor: "#252525",
  height: "50px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "10px",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "flex-start",
    height: "100px",
  },
}));

const OrderInfo = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}));

const ProductInfo = styled(Box)({
  display: "flex",
  justifyContent: "space-evenly",
  width: "100%",
});

export const TrackOrderTemplate = () => {
  // State
  const dispatch = useDispatch();
  const { isLoading, orderId, orderDetails } = useSelector(
    (state) => state.orderDetails
  );

  const handleOnChange = (event) => {
    const { value } = event.target;
    dispatch(setOrderId(value));
  };

  const fetchOrderDetails = async (event) => {
    event.preventDefault();
    dispatch(setIsLoading_Order(true));
    try {
      let response = await main.orderById(orderId);
      dispatch(setOrderDetails(response.result));
      dispatch(setIsLoading_Order(false));
    } catch (error) {
      dispatch(
        setNotification({
          visible: true,
          status: "error",
          message: error,
        })
      );
      dispatch(setIsLoading_Order(false));
    }
  };

  return (
    <StyledContainer>
      <FormContainer component={"form"} onSubmit={fetchOrderDetails}>
        <Typography variant="h3">Track your order</Typography>
        <Typography variant="h6">
          Enter your order details below to get the latest status.
        </Typography>
        <InputField
          margin="normal"
          label="Order number"
          value={orderId}
          required={true}
          fullWidth={true}
          onChange={handleOnChange}
        />

        <Box sx={{ margin: "20px 0" }}>
          {isLoading ? (
            <LoadingIndicator />
          ) : (
            <ButtonCustom label="Track order" type="submit" />
          )}
        </Box>
      </FormContainer>
      {orderDetails._id === orderId && (
        <ProductsContainer>
          <Typography variant="h1">Order Details</Typography>
          <OrderDetailsBox>
            <OrderHeader>
              <OrderInfo>Email : {orderDetails.user.email}</OrderInfo>

              <OrderInfo>Phone number: {orderDetails.user.phoneNo}</OrderInfo>

              <OrderInfo>
                Address:{" "}
                {`${orderDetails.address.street} ${orderDetails.address.pincode} ${orderDetails.address.city} ${orderDetails.address.state}`}
              </OrderInfo>
            </OrderHeader>

            {orderDetails?.products?.map((item, index) => (
              <ProductItem key={index}>
                <ProductImagesDetails image={item.image} size={item.size} />
                <ProductInfo>
                  <Box>
                    <Typography margin={"10px"} variant="h6">
                      {item.name}
                    </Typography>
                    <Typography margin={"10px"} variant="h5">
                      {item.paperType}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography margin={"10px"} variant="h6">
                      RS {item.price} /-
                    </Typography>
                    <Typography margin={"10px"} variant="h5">
                      Quantity {item.quantity}
                    </Typography>
                  </Box>
                </ProductInfo>
              </ProductItem>
            ))}
            <OrderHeader>
              <OrderInfo>
                Order created:{" "}
                {moment(orderDetails.createdAt).format("DD/MM/YYYY")}
              </OrderInfo>

              <OrderInfo>Total price: {orderDetails.totalPrice}</OrderInfo>

              <OrderInfo>Order number: {orderId}</OrderInfo>
            </OrderHeader>
          </OrderDetailsBox>
        </ProductsContainer>
      )}
    </StyledContainer>
  );
};
