import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    isLoading: false,
    totalPrice: 0,
    discountPrice: 0,
    cartProducts: [],
    address: {
      street: "",
      pincode: "",
      state: "",
      city: "",
    },
    user: {
      phoneNo: "",
      fullname: "",
      email: "",
    },
  },
  reducers: {
    setIsLoading_cart: (state, { payload }) => {
      state.isLoading = payload;
    },
    setTotalPrice: (state, { payload }) => {
      state.totalPrice = state.totalPrice + payload;
    },
    setDiscountPrice: (state, { payload }) => {
      state.discountPrice = state.discountPrice + payload;
    },
    setCartProduct: (state, { payload }) => {
      state.cartProducts = payload;
      state.totalPrice = state.cartProducts.reduce(
        (acc, item) => acc + (item.price || 0),
        0
      );
    },
    setUserAddress: (state, { payload }) => {
      state.address = payload;
    },
    setUserInfo: (state, { payload }) => {
      state.user = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setIsLoading_cart,
  setTotalPrice,
  setCartProduct,
  setUserAddress,
  setUserInfo,
} = cartSlice.actions;

export const cartReducer = cartSlice.reducer;
