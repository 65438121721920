import React from "react";
import { styled } from "@mui/system";
import { Box, Typography } from "@mui/material";
import { ButtonCustom } from "../../atoms";
import { useNavigate } from "react-router-dom";

const CartTotalContainer = styled(Box)(({ theme }) => ({
  width: "80%",
  margin: "20px auto",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "flex-end",
  flexDirection: "column",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const ProductNameText = styled(Typography)({
  fontSize: "16px",
});


export const GrandTotalPrice = ({ totalPrice }) => {
  const navigate = useNavigate();
  return (
    <CartTotalContainer>
      <CartTotalContainer style={{ width: "100%" }}>
        <ProductNameText>Grand Total Rs. {totalPrice}</ProductNameText>
      </CartTotalContainer>

        <ButtonCustom label={"Buy now"} onClick={() => navigate("/buynow")} />
    </CartTotalContainer>
  );
};
