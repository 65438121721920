import React, { useState } from "react";
import { styled } from "@mui/system";
import {
  Button,
  TextField,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  FormGroup,
  Typography,
} from "@mui/material";
import { ImageUpload } from "../../../molicules";
import {
  createPost,
  uploadMultipleMedia,
  uploadSingleMedia,
} from "../../../../utils/api/apiCall";
import { LoadingIndicator } from "../../../atoms";
//redux
import { setNotification } from "../../../../redux/reducer/commonReducer";
import { useDispatch, useSelector } from "react-redux";

// Styled components
const Container = styled("div")(({ theme }) => ({
  padding: theme.spacing(3),
  maxWidth: "600px",
  margin: "auto",
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

// DesignForm component
export const CreatePostTemplate = () => {
  //Redux
  const { navbarGenre } = useSelector((state) => state.commonDetails);
  const dispatch = useDispatch();

  const [postData, setPostDate] = useState({
    thumbnail: "",
    name: "",
    imageRotationType: "",
    images: [],
    genre: "",
    paperType: "Both",
    isMultiple: false,
    image_quantity: NaN,
  });

  const [singleImage, setSingleImage] = useState({});
  const [multipleImage, setMultipleImage] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  // Handle file input changes
  const handleFileChange = (fileArray) => {
    if (fileArray.length === 1) {
      setSingleImage(fileArray[0]);
    } else {
      setMultipleImage(fileArray);
    }
  };

  const handleDeleteImage = (index) => {
    const updatedImages = postData.images.filter((_, i) => i !== index);
    const multipleImages = multipleImage;
    multipleImages.splice(index, 1);
    setPostDate((prevData) => ({ ...prevData, images: updatedImages }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setPostDate({
      ...postData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    if (!singleImage) {
      setIsLoading(false);
      return alert("Please select images.");
    }

    if (postData.name === "" || postData.genre === "") {
      setIsLoading(false);
      return alert("Please fill all the fields");
    }

    const singleImageData = new FormData();
    singleImageData.append("file", singleImage, singleImage.name);

    try {
      const { success, result: singleImageResult } = await uploadSingleMedia(
        singleImageData
      );

      if (postData.name) {
        const data = {
          thumbnail: singleImageResult.uri,
          name: postData.name,
          imageRotationType: postData.imageRotationType,
          genre: postData.genre,
          paperType: postData.paperType,
          isMultiple: postData.isMultiple,
          image_quantity: postData.image_quantity,
        };

        const postDataResult = await createPost(data);
        if (postDataResult.success) {
          setIsLoading(false);
          dispatch(
            setNotification({
              visible: true,
              message: "Post has been successfully creted",
              status: "success",
            })
          );
          // navigate("/");
          return;
        }
      } else {
        dispatch(
          setNotification({
            visible: true,
            message: "Some fields are missing or incorrect.",
            status: "warning",
          })
        );
        setIsLoading(false);
      }
    } catch (error) {
      dispatch(
        setNotification({
          visible: true,
          message: error,
          status: "error",
        })
      );
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <Typography variant="h4">Submit Design Details</Typography>
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <InputLabel>Thumbnail:</InputLabel>
          <ImageUpload
            name="thumbnail"
            label="Upload Thumbnail"
            accept="image/*"
            onFileChange={(files) => handleFileChange(files)}
            filePreview={singleImage}
          />
        </FormGroup>
        <FormGroup>
          <TextField
            fullWidth
            label="Name of the desing"
            name="name"
            value={postData.name}
            onChange={handleChange}
            placeholder="Enter name"
            margin="normal"
          />
        </FormGroup>

        <FormGroup>
          <FormControl fullWidth margin="normal">
            <InputLabel>Image Rotation Type</InputLabel>
            <Select
              name="imageRotationType"
              value={postData.imageRotationType}
              onChange={handleChange}
            >
              <MenuItem value="horizontal">Horizontal</MenuItem>
              <MenuItem value="vertical">Vertical</MenuItem>
            </Select>
          </FormControl>
        </FormGroup>

        <FormGroup>
          <FormControl fullWidth margin="normal">
            <InputLabel>genre</InputLabel>
            <Select name="genre" value={postData.genre} onChange={handleChange}>

              {navbarGenre.map((item,index)=>{
                return(
                  <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </FormGroup>

        <FormGroup>
          <FormControl fullWidth margin="normal">
            <InputLabel>Paper type</InputLabel>
            <Select
              name="paperType"
              value={postData.paperType}
              onChange={handleChange}
            >
              <MenuItem value="Both">Both</MenuItem>
              <MenuItem value="Canvas">Canvas</MenuItem>
              <MenuItem value="Poster">Poster</MenuItem>
            </Select>
          </FormControl>
        </FormGroup>

        {postData.paperType === "Poster" && (
          <FormGroup>
            <FormControl fullWidth margin="normal">
              <InputLabel>Is Poster are multiple (optional)</InputLabel>
              <Select
                name="isMultiple"
                value={postData.isMultiple}
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </FormGroup>
        )}

        {postData.isMultiple && postData.paperType === "Poster" && (
          <FormGroup>
            <TextField
              fullWidth
              label="Quantity of images"
              name="image_quantity"
              value={postData.image_quantity}
              onChange={handleChange}
              placeholder="Enter name quantity"
              margin="normal"
              type="number"
            />
          </FormGroup>
        )}

        {/* <FormGroup>
          <InputLabel>Additional Images:</InputLabel>
          <ImageUpload
            name="images"
            label="Upload Additional Images"
            accept="image/*"
            multiple
            onFileChange={(files) => handleFileChange(files)}
            filePreview={postData.images.map((image) => image)}
            onDeleteImage={handleDeleteImage}
          />
        </FormGroup> */}
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <SubmitButton variant="contained" color="primary" type="submit">
            Submit
          </SubmitButton>
        )}
      </form>
    </Container>
  );
};
