export const mockups = {
  vertical: {
    vertical_1: require("./vertical/vertical_1.jpg"),
    vertical_2: require("./vertical/vertical_2.png"),
    vertical_3: require("./vertical/vertical_3.png"),
    vertical_4: require("./vertical/vertical_4.png"),
    vertical_5: require("./vertical/vertical_5.jpg"),
  },
  horizontal: {
    horizontal_1: require("./horizontal/horizontal_1.jpg"),
    horizontal_2: require("./horizontal/horizontal_2.jpg"),
    horizontal_3: require("./horizontal/horizontal_3.jpg"),
    horizontal_4: require("./horizontal/horizontal_4.jpg"),
    horizontal_5: require("./horizontal/horizontal_5.png"),
  },
  posterVertical: {
    vertical_1: require("./poster/posterVertical.jpg"),
  },
  posterHorizontal: {
    horizontal_1: require("./poster/posterHorizontal.jpg"),
  },
};
