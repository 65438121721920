import React from "react";
import { Navbar, Footer } from "../../../organisms";
import { CartTemplate } from "../../../Template";
import { ScrollOnTop } from "../../../wrapper";

export const AddToCart = () => {
  return (
    <ScrollOnTop>
      <Navbar />
      <CartTemplate />
      <Footer />
    </ScrollOnTop>
  );
};
