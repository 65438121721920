import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { ButtonCustom, InputField, LoadingIndicator } from "../../../atoms";
import { ProductImagesDetails } from "../../../molicules";
import { useDispatch, useSelector } from "react-redux";
import { logo } from "../../../../assets";
import {
  setCartProduct,
  setIsLoading_cart,
  setTotalPrice,
  setUserAddress,
  setUserInfo,
} from "../../../../redux/reducer/main/cartReducer";
import { setNotification } from "../../../../redux/reducer/commonReducer";
import { main } from "../../../../utils/api/apiCall";
import { useNavigate } from "react-router-dom";

// Styled components
const Container = styled(Box)({
  width: "100%",
});

const ContentWrapper = styled(Box)(({ theme }) => ({
  width: "80%",
  margin: "50px auto",
  display: "flex",
  boxSizing: "border-box",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column-reverse",
  },
}));

const FormContainer = styled(Box)(({ theme }) => ({
  width: "50%",
  height: "90%",
  padding: "50px 0",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    padding: "20px 0",
  },
}));

const ProductsContainer = styled(Box)(({ theme }) => ({
  width: "50%",
  height: "90%",
  padding: "50px 30px",
  boxSizing: "border-box",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    padding: "20px 15px",
  },
}));

const ProductItem = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  marginBottom: "20px",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
}));

const FormFieldContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

const Header = styled(Typography)(({ theme }) => ({
  marginBottom: "20px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.5rem",
  },
}));

export const BuyNowTemplate = () => {
  //State
  const { cartProducts, totalPrice, address, user, isLoading } = useSelector(
    (state) => state.cartDetails
  );
  const dispatch = useDispatch();

  //Navigate
  const navigate = useNavigate();

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");

      script.src = src;

      script.onload = () => {
        resolve(true);
      };

      script.onerror = () => {
        resolve(false);
      };

      document.body.appendChild(script);
    });
  };

  const handleAddresChange = (event) => {
    let { value, name } = event.target;

    let newAddres = { ...address, [name]: value };

    dispatch(setUserAddress(newAddres));
  };

  const handleUserInfoChange = (event) => {
    let { value, name } = event.target;

    let newUser = { ...user, [name]: value };

    dispatch(setUserInfo(newUser));
  };

  const handleCheckout = async (event) => {
    event.preventDefault();

    try {
      dispatch(setIsLoading_cart(true));
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );

      if (!res) {
        dispatch(
          setNotification({
            visible: true,
            message:
              "You are offline or something went wrong. Please try again later.",
            status: "error",
          })
        );
        dispatch(setIsLoading_cart(false));
        return;
      }

      var options = {
        key: "rzp_live_Ry2736m3DSLxN4", // Enter the Key ID generated from the Dashboard
        amount: totalPrice * 100,
        currency: "INR",
        name: "krince",
        description: "Your faith is the key of our success",
        image: logo,
        handler: async (response) => {
          dispatch(
            setNotification({
              visible: true,
              message: "Payment was successful.",
              status: "success",
            })
          );

          const configOrder = {
            RazorpayiD: response.razorpay_payment_id,
            totalPrice,
            products: cartProducts.map((item) => {
              const {
                price,
                name,
                quantity,
                id,
                size,
                imageRotationType,
                image,
                paperType,
                designerID,
                designerName,
                isMultiple,
                image_quantity,
              } = item;
              return {
                price,
                name,
                quantity,
                postID: id,
                size,
                imageRotationType,
                image,
                paperType,
                designerID,
                designerName,
                isMultiple,
                image_quantity,
              };
            }),
            address,
            user,
          };

          dispatch(setIsLoading_cart(false));

          const orderResponse = await main.createOrder(configOrder);
          if (orderResponse.success) {
            dispatch(setUserAddress({}));
            dispatch(setUserInfo({}));
            dispatch(setTotalPrice(0));
            dispatch(setCartProduct([]));
            dispatch(setIsLoading_cart(false));
            dispatch(
              setNotification({
                visible: true,
                message: "Your order has been confirmed.",
                status: "success",
              })
            );
            navigate("/");
          }else{
            dispatch(setIsLoading_cart(false));
          }
        },
        prefill: {
          name: user.fullName,
          email: user.email,
          contact: user.phoneNo,
        },
        theme: { color: "#252525" },
        modal: {
          ondismiss: function () {
            dispatch(
              setNotification({
                statue: "warning",
                message: "Checkout form closed",
                visible: true,
              })
            );
            dispatch(setIsLoading_cart(false));
          },
        },
        notes: {
          ...address,
          ...user,
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      dispatch(
        setNotification({
          statue: "error",
          message: error,
          visible: true,
        })
      );
      dispatch(setIsLoading_cart(false));
    }
  };

  return (
    <Container>
      <ContentWrapper>
        <FormContainer component="form" onSubmit={handleCheckout}>
          <Header variant="h1">Delivery Details</Header>
          <InputField
            margin="normal"
            name="phoneNo"
            required={true}
            label="Phone no"
            value={user.phoneNo}
            onChange={handleUserInfoChange}
          />
          <InputField
            margin="normal"
            name="email"
            required={true}
            label="Email"
            value={user.email}
            onChange={handleUserInfoChange}
          />
          <InputField
            margin="normal"
            name="fullname"
            required={true}
            label="Full name"
            value={user.fullname}
            onChange={handleUserInfoChange}
          />
          <InputField
            margin="normal"
            name="street"
            required={true}
            label="Address"
            value={address.street}
            onChange={handleAddresChange}
          />
          <FormFieldContainer>
            <InputField
              margin="normal"
              name="pincode"
              required={true}
              label="Pin code"
              value={address.pincode}
              onChange={handleAddresChange}
            />
            <InputField
              margin="normal"
              name="city"
              required={true}
              label="City"
              value={address.city}
              onChange={handleAddresChange}
            />
            <InputField
              margin="normal"
              name="state"
              required={true}
              label="State"
              value={address.state}
              onChange={handleAddresChange}
            />
          </FormFieldContainer>
          <Box style={{ margin: "20px 0" }}>
            {isLoading ? (
              <LoadingIndicator />
            ) : (
              <ButtonCustom label="Pay now" type="submit" />
            )}
          </Box>
        </FormContainer>
        <ProductsContainer>
          <Header variant="h1">Products Details</Header>
          {cartProducts.map((item, index) => (
            <ProductItem key={index}>
              <ProductImagesDetails image={item.image} size={item.size} />
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  width: "100%",
                }}
              >
                <Box>
                  <Typography margin={"10px"} variant="h6">
                    {item.name}
                  </Typography>
                  <Typography margin={"10px"} variant="h5">
                    {item.paperType}
                  </Typography>
                </Box>
                <Box>
                  <Typography margin={"10px"} variant="h6">
                    RS {item.price} /-
                  </Typography>
                  <Typography margin={"10px"} variant="h5">
                    Quantity {item.quantity}
                  </Typography>
                </Box>
              </Box>
            </ProductItem>
          ))}
        </ProductsContainer>
      </ContentWrapper>
    </Container>
  );
};
