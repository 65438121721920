import { Routes, Route } from "react-router-dom";
import { route, adminRoutes } from "./routes";
import { useSelector } from "react-redux";
import { ToastNotification } from "./components/molicules";

const App = () => {
  const { userRole } = useSelector((state) => state.userDetails);
  return (
    <>
      <Routes>
        {route.map((item, index) => {
          const { path, element } = item;
          return <Route path={path} element={element} key={index} />;
        })}
        {userRole === "admin" &&
          adminRoutes.map((item, index) => {
            const { path, element } = item;
            return <Route path={path} element={element} key={index} />;
          })}
      </Routes>
      <ToastNotification />
    </>
  );
};

export default App;
