import { configureStore } from "@reduxjs/toolkit";
import { reducers } from "./reducer";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistedConfig = {
  key: "root",
  version: 1,
  storage: storage,
};

const persistedReducer = persistReducer(persistedConfig, reducers);

export default configureStore({
  reducer: persistedReducer,
});
