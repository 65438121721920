import React, { useState } from "react";
import { Box, Typography, TextField } from "@mui/material";
import { styled } from "@mui/system";
import { ButtonCustom } from "../../../atoms";
import { main } from "../../../../utils/api/apiCall";
import { useDispatch } from "react-redux";
import { setNotification } from "../../../../redux/reducer/commonReducer";

const ContactContainer = styled(Box)(({ theme }) => ({
  width: "80%",
  margin: "0 auto",
  padding: "20px",
  boxSizing: "border-box",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    padding: "10px",
  },
}));

const FormContainer = styled(Box)(({ theme }) => ({
  marginTop: "20px",
  [theme.breakpoints.down("sm")]: {
    marginTop: "10px",
  },
}));

export const ContactUsTemplate = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let response = await main.contactUs(formData);
      dispatch(
        setNotification({
          visible: true,
          message: response.message,
          status: "success",
        })
      );
      setFormData({ name: "", email: "", message: "" });
    } catch (error) {
      dispatch(
        setNotification({
          visible: false,
          message:
            "There was an error processing your request. Please try again later.",
          status: "error",
        })
      );
    }
  };

  return (
    <ContactContainer>
      <Typography variant="h2" gutterBottom>
        Contact Us
      </Typography>
      <Typography variant="h5" paragraph>
        We would love to hear from you! Please use the contact form below to get
        in touch with us. We'll respond to your inquiry as soon as possible.
      </Typography>
      <FormContainer component={"form"} onSubmit={handleSubmit}>
        <Typography variant="h5" gutterBottom>
          Send us a message
        </Typography>
        <TextField
          fullWidth
          label="Name"
          margin="normal"
          variant="outlined"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />
        <TextField
          fullWidth
          label="Email"
          margin="normal"
          variant="outlined"
          name="email"
          type="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <TextField
          fullWidth
          label="Message"
          margin="normal"
          variant="outlined"
          name="message"
          multiline
          rows={4}
          value={formData.message}
          onChange={handleChange}
          required
        />
        <ButtonCustom label={"Submit"} type={"submit"} />
      </FormContainer>
    </ContactContainer>
  );
};
