import React from "react";
import { CardContent, Typography } from "@mui/material";
import { styled } from "@mui/system";

const CardContainer = styled(CardContent)(({ theme }) => ({
  maxHeight: "15%",
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    padding: "0px !important",
  },
}));

const StrikethroughText = styled(Typography)(({ theme }) => ({
  textDecoration: "line-through",
  color: "gray",
  [theme.breakpoints.down("sm")]: {
    textDecoration: "none",
    color: "black",
    fontSize: "0.8em",
  },
}));

const SpacerText = styled(Typography)(({ theme }) => ({
  margin: "0px 5px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.8em",
  },
}));

const ResponsiveTypography = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.8em",
  },
}));

export const DiscountLabel = ({ discountedPrice }) => {
  return (
    <CardContainer>
      <ResponsiveTypography>
        You get  20% Discount = Rs {discountedPrice}
      </ResponsiveTypography>
    </CardContainer>
  );
};
