export const cart = require("./cart.png")
export const user = require("./user.png")
export const Facebook = require("./Facebook.png")
export const X = require("./X.png");
export const Instagram = require("./Instagram.png")
export const LinkedIn = require("./LinkedIn.png");
export const Youtube = require("./Youtube.png");
export const logo = require("./logo.png");
export const scale = require("./scale.png");
export const hang = require("./hang.png");
export const india = require("./india.png");
export const eco = require("./eco.png");
export const WoodenFrames = require("./WoodenFrames.png");
export const canvas = require("./canvas.png");
export const quality = require("./quality.png");


