import { createSlice } from "@reduxjs/toolkit";

const genreSlice = createSlice({
  name: "genre",
  initialState: {
    isLoading: true,
    genrePost: [],
  },
  reducers: {
    setIsLoading_Genre: (state, { payload }) => {
      state.isLoading = payload;
    },
    setGenre: (state, { payload }) => {
      state.genrePost = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setIsLoading_Genre, setGenre } = genreSlice.actions;

export const genreReducer = genreSlice.reducer;
