import { createSlice } from "@reduxjs/toolkit";

const homeSlice = createSlice({
  name: "home",
  initialState: {
    isLoading: true,
    homePageData: {
      bannerImageOne: "",
      bannerImageTwo: "",
      bannerImageThree: "",
      horizontalDesigns: [],
      canvasDesigns: [],
      posterDesigns: [],
      bestSales: [],
    },
  },
  reducers: {
    setIsLoading_Home: (state, { payload }) => {
      state.isLoading = payload;
    },
    setHomePageData: (state, { payload }) => {
      state.homePageData = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setIsLoading_Home, setHomePageData } = homeSlice.actions;

export const homeReducer = homeSlice.reducer;
