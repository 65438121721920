import React, { useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { ProductCard } from "../../molicules";
import { useNavigate } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";

const BestSellContainer = styled(Box)(({ theme }) => ({
  width: "80%",
  padding: "20px",
  margin: "0 auto",
  [theme.breakpoints.down("md")]: {
    padding: "80px 8%",
  },
}));

const HeadingContainer = styled(Typography)({
  margin: "20px 0",
});

const IconMainContainer = styled(Box)({
  justifyContent: "space-between",
  display: "flex",
  flexWrap: "wrap",
  gap: "20px",
});

export const CategoryProduct = ({ label, productData }) => {
  let navigate = useNavigate();

  useEffect(() => {
    Aos.init({
      offset: 200,
      duration: 500,
      easing: "ease-in-sine",
      delay: 50,
    });
  }, []);

  return (
    <BestSellContainer>
      <HeadingContainer variant="h2" data-aos="fade-up">{label}</HeadingContainer>
      <IconMainContainer>
        <Grid container spacing={2}>
          {productData?.map((item, index) => {
            const postID = item?._id;
            return (
              <Grid item xs={12} sm={6} md={4} lg={4} key={index}  data-aos="fade-up">
                <ProductCard
                  imageName={item.thumbnail}
                  onClick={() => navigate(`/productdetails/${postID}`)}
                  paperType={item.paperType}
                  image_quantity={item.image_quantity}
                  isMultiple={item.isMultiple}
                  name={item.name}
                />
              </Grid>
            );
          })}
        </Grid>
      </IconMainContainer>
    </BestSellContainer>
  );
};
