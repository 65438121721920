import { createSlice } from "@reduxjs/toolkit";

const commonSlice = createSlice({
  name: "common",
  initialState: {
    navbarGenre: [],
    notification: {
      visible: false,
      message: "",
      status: "",
    },
  },
  reducers: {
    setNotification: (state, { payload }) => {
      state.notification = payload;
    },
    setNavbarGenre: (state, { payload }) => {
      state.navbarGenre = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setNotification, setNavbarGenre } = commonSlice.actions;

export const commonReducer = commonSlice.reducer;
