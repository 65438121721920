import { createSlice } from "@reduxjs/toolkit";

const productDetailsSlice = createSlice({
  name: "productDetails",
  initialState: {
    isLoading: true,
    productId: 0,
    paperType: "Poster",
    paperTypeData: [],
    rotationType: "",
    sizeChart: [],
    originalPrice: "",
    discountedPrice: "",
    selectedSize: 0,
    productItemDetails: {},
    similarPost: [],
    designerDetails: {},
  },
  reducers: {
    setIsLoading_productDetails: (state, { payload }) => {
      state.isLoading = payload;
    },
    setProductId: (state, { payload }) => {
      state.productId = payload;
    },
    setPaperType: (state, { payload }) => {
      state.paperType = payload;
    },
    setPaperTypeData: (state, { payload }) => {
      state.paperTypeData = payload;
    },
    setRotationType: (state, { payload }) => {
      state.rotationType = payload;
    },
    setSizeChart: (state, { payload }) => {
      state.sizeChart = payload;
    },
    setOriginalPrice: (state, { payload }) => {
      state.originalPrice = payload;
    },
    setDiscountPrice: (state, { payload }) => {
      state.discountedPrice = payload;
    },
    setActiveSize: (state, { payload }) => {
      state.selectedSize = payload;
    },
    setProductDetails: (state, { payload }) => {
      state.productItemDetails = payload;
    },
    setDesignerDetails: (state, { payload }) => {
      state.designerDetails = payload;
    },
    setSimilarPost: (state, { payload }) => {
      state.similarPost = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setIsLoading_productDetails,
  setProductId,
  setPaperType,
  setPaperTypeData,
  setRotationType,
  setSizeChart,
  setOriginalPrice,
  setDiscountPrice,
  setActiveSize,
  setProductDetails,
  setDesignerDetails,
  setSimilarPost,
} = productDetailsSlice.actions;
export const productDetailsReducer = productDetailsSlice.reducer;
