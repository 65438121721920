import React from "react";
import { Snackbar, Alert } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { setNotification } from "../../../redux/reducer/commonReducer";

export const ToastNotification = () => {
  const { notification } = useSelector((state) => state.commonDetails);
  const dispatch = useDispatch();

  const handleClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(
      setNotification({
        visible: false,
        message: "",
        status: "",
      })
    );
  };

  return (
    <Snackbar
      open={notification.visible}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert
        onClose={handleClose}
        severity={notification.status || "info"}
        sx={{ width: "100%" }}
      >
        {notification.message}
      </Alert>
    </Snackbar>
  );
};
