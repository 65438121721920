import React from "react";
import { styled } from "@mui/system";
import { Box } from "@mui/material";
import { Facebook, Instagram, LinkedIn } from "../../../assets";
import { useNavigate } from "react-router-dom";

const FooterContainer = styled(Box)({
  backgroundColor: "#252525",
  padding: "50px 0px",
});

const FlexContainer = styled(Box)(({ theme }) => ({
  width: "80%",
  margin: "0px auto",
  display: "flex",
  justifyContent: "space-between",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    textAlign: "center",
    alignItems: "center",
  },
}));

const Column = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  [theme.breakpoints.down("md")]: {
    alignItems: "center",
    marginBottom: "20px",
  },
}));

const SocialIcons = styled(Box)({
  display: "flex",
  gap: "10px",
  justifyContent: "center",
  margin: "20px auto",
});

const FooterText = styled(Box)({
  fontSize: "18px",
  fontFamily: "'Inria Sans', Helvetica, sans-serif",
  color: "#ffffff",
});

const FooterLink = styled(Box)({
  fontSize: "18px",
  fontFamily: "'Inria Sans', Helvetica, sans-serif",
  color: "#ffffff",
  cursor: "pointer",
  "&:hover": {
    textDecoration: "underline",
  },
});

const Icon = styled("img")({
  width: "24px",
  height: "24px",
  cursor: "pointer",
});

export const Footer = () => {
  const navigate = useNavigate();
  return (
    <FooterContainer>
      <FlexContainer>
        <Column>
          <FooterText>Info</FooterText>
          {/* <FooterLink>About us</FooterLink> */}
          <FooterLink onClick={() => navigate("/trackorder")}>
            Track your order
          </FooterLink>
          <FooterLink onClick={() => navigate("/privacypolicies")}>
            Privacy policies
          </FooterLink>
          <FooterLink onClick={() => navigate("/refundpolicies")}>
            Refund policies
          </FooterLink>
          <FooterLink onClick={() => navigate("/shippingpolicies")}>
            Shipping policies
          </FooterLink>
          <FooterLink onClick={() => navigate("/termcondition")}>
            Terms & Conditions
          </FooterLink>
          {/* <FooterLink>FAQ</FooterLink> */}
        </Column>
        <Column>
          <FooterText>Address</FooterText>
          <FooterText>J120 A, MIG Colony, Lig, Indore (M.P)</FooterText>
          <FooterText>Email: support@krince.in</FooterText>
          <FooterText>Contact: +91 7828749578</FooterText>
        </Column>
      </FlexContainer>
      <SocialIcons>
        <a
          href="https://www.facebook.com/people/Krincein/61562470377090/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon src={Facebook} alt="Facebook" />
        </a>
        <a
          href="https://www.instagram.com/krince.in/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon src={Instagram} alt="Instagram" />
        </a>

        <a
          href="https://in.linkedin.com/company/krince"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon src={LinkedIn} alt="LinkedIn" />
        </a>
      </SocialIcons>
    </FooterContainer>
  );
};
