import React from "react";
import { Box, styled, Typography } from "@mui/material";

const ContentBox = styled(Box)(({ theme }) => ({
  minHeight: "100vh",
  padding: "20px",
  width: "60%",
  margin: "20px auto",
  [theme.breakpoints.down("sm")]: {
    width: "80%",
  },
}));

const ListItemBoxForDetails = styled(Box)({
  marginBottom: "20px",
});

export const RefundPoliciesTemplate = () => {
  return (
    <ContentBox>
      <Typography variant="h1" fontWeight="500" margin={"50px 0px"}>
        Return and Refund Policy
      </Typography>

      <ListItemBoxForDetails>
        <Typography variant="h6" margin>
          At krince.in, we take great pride in the quality of our products and
          aim to ensure that you are completely satisfied with your purchase. If
          you encounter any issues with your order, please reach out to our
          customer support team within 2 days of receiving your product at
          support@krince.in. We are here to assist you and resolve any concerns
          you may have. For detailed information on our return process, please
          refer to the return policy section on our website.
        </Typography>
      </ListItemBoxForDetails>

      <ListItemBoxForDetails>
        <Typography variant="h6" margin>
          If you receive a damaged or misprinted product, simply contact us, and
          we will initiate the refund process without any hassle. At krince.in,
          we want to ensure that our customers are happy with their purchase and
          receive the product they expected. Therefore, we offer an instant
          refund for all damaged or misprinted products.
        </Typography>
        <Typography variant="h4" fontWeight="500" margin>
          Please note the following conditions for our refund policy:
        </Typography>

        <Typography variant="h6" fontWeight="400" margin>
          - The product must be returned within 2 days of delivery.
        </Typography>

        <Typography variant="h6" fontWeight="400" margin>
          - It must be in its original packaging. Original packaging is required
          as proof for processing returns or issuing replacements.
        </Typography>

        <Typography variant="h6" fontWeight="400" margin>
          - Products that are used or damaged by the customer, or not returned
          in their original packaging, may not be eligible for a full refund.
        </Typography>
      </ListItemBoxForDetails>

      <ListItemBoxForDetails>
        <Typography variant="h6" margin>
          If you have any questions or concerns regarding our refund policy, do
          not hesitate to contact us. Our customer service team at krince.in is
          always ready to assist you and ensure a positive shopping experience.
        </Typography>
      </ListItemBoxForDetails>

      <ListItemBoxForDetails>
        <Typography variant="h6" margin>
          Due to the custom nature of our products, krince.in does not pre-print
          or hold inventory. Each item is printed on demand and made to order.
          Consequently, we are unable to accept returns or exchanges for
          different pieces of art. Refunds, credits, or replacements are only
          provided in cases of damage before the product reaches you.
        </Typography>
      </ListItemBoxForDetails>

      <ListItemBoxForDetails>
        <Typography variant="h6" margin>
          Additionally, krince.in does not offer a cancellation option once an
          order has been placed. While we understand that cancellation might be
          necessary in certain situations, our streamlined order processing
          system does not allow for cancellations once an order is submitted.
        </Typography>
      </ListItemBoxForDetails>

      <ListItemBoxForDetails>
        <Typography variant="h6" margin>
          We encourage all customers to carefully review their orders before
          finalizing them to ensure accuracy in product selection, quantity,
          shipping address, and payment information.
        </Typography>
      </ListItemBoxForDetails>

      <ListItemBoxForDetails>
        <Typography variant="h6" margin>
          We apologize for any inconvenience this policy may cause and
          appreciate your understanding. This policy helps us maintain high
          standards of customer service and efficient order processing.
        </Typography>
      </ListItemBoxForDetails>
    </ContentBox>
  );
};
