import { createTheme } from "@mui/material/styles";

// Create a MUI theme
export const theme = createTheme({
  palette: {
    primary: {
      main: "#252525",
      light: "#63a4ff",
      dark: "#115293",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#dc004e",
      light: "#e33371",
      dark: "#9a0036",
      contrastText: "#ffffff",
    },
    background: {
      default: "#f5f5f5",
      paper: "#ffffff",
    },
    text: {
      primary: "#333333",
      secondary: "#555555",
    },
  },
  typography: {
    fontFamily: "'Inria Sans', Helvetica, sans-serif",
    fontWeightRegular: 300,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontSize: "2.5rem",
      fontWeight: 300,
    },
    h2: {
      fontSize: "2rem",
      fontWeight: 300,
    },
    h3: {
      fontSize: "1.75rem",
      fontWeight: 300,
    },
    h4: {
      fontSize: "1.5rem",
      fontWeight: 300,
    },
    h5: {
      fontSize: "1.25rem",
      fontWeight: 300,
    },
    h6: {
      fontSize: "1rem",
      fontWeight: 300,
    },
    body1: {
      fontSize: "1rem",
      fontWeight: 300,
    },
    body2: {
      fontSize: "0.875rem",
      fontWeight: 300,
    },
  },
});