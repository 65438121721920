import React from "react";
import { Box, styled } from "@mui/material";
import { LoadingIndicator } from "../../atoms"; // Adjust the import based on your file structure

const StyledBox = styled(Box)({
  width: "100%",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const FullViewComponent = () => (
  <StyledBox>
    <LoadingIndicator />
  </StyledBox>
);
