import React from "react";
import { Footer, Navbar } from "../../../organisms";
import { GenreTemplate } from "../../../Template/mainTemplate/genreTemplate";
import { ScrollOnTop } from "../../../wrapper";

export const Genre = () => {
  return (
    <ScrollOnTop>
      <Navbar />
      <GenreTemplate />
      <Footer />
    </ScrollOnTop>
  );
};
