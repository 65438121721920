import { createSlice } from "@reduxjs/toolkit";

const orderSlice = createSlice({
  name: "genre",
  initialState: {
    isLoading: false,
    orderId: "",
    orderDetails: {},
  },
  reducers: {
    setIsLoading_Order: (state, { payload }) => {
      state.isLoading = payload;
    },
    setOrderId: (state, { payload }) => {
      state.orderId = payload;
    },
    setOrderDetails: (state, { payload }) => {
      state.orderDetails = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setIsLoading_Order, setOrderDetails, setOrderId } =
  orderSlice.actions;

export const orderReducer = orderSlice.reducer;
