import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { ButtonCustom } from "../../../atoms";
import { useNavigate } from "react-router-dom";

const EarnWithUsContainer = styled(Box)(({ theme }) => ({
  width: "90%",
  margin: "0 auto",
  height: "80vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: "20px",
  boxSizing: "border-box",
  [theme.breakpoints.down("md")]: {
    padding: "15px",
    width: "90%",
    height: "auto",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "10px",
    width: "100%",
  },
}));

const ResponsiveTypography = styled(Typography)(({ theme }) => ({
  margin: "10px 0",
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.9em",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "1.1em",
  },
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  margin: "40px 0",
}));

const FeatureText = ({ boldText, children }) => (
  <ResponsiveTypography variant="h5">
    <Box component="span" fontWeight="bold">
      {boldText}
    </Box>
    : {children}
  </ResponsiveTypography>
);

export const EarnWithUsTemplate = () => {
  const navigate = useNavigate();

  return (
    <EarnWithUsContainer>
      <Typography
        variant="h1"
        sx={{
          mb: 2,
          fontSize: { xs: "1.5rem", sm: "2rem", md: "2.5rem" },
        }}
      >
        Join Our Platform and Start Earning Today!
      </Typography>
      <Typography
        variant="h4"
        sx={{
          mb: 2,
          fontSize: { xs: "1rem", sm: "1.2rem",},
        }}
      >
        Whether you are a freelancer or looking for a side hustle, we have
        opportunities for you. Join our community, showcase your designs, and
        earn from your creativity.
      </Typography>
      <FeatureText boldText="Sign Up">
        Create an account on our platform.
      </FeatureText>
      <FeatureText boldText="Upload Your Designs">
        Share your creative work with our community.
      </FeatureText>
      <FeatureText boldText="Earn from Sales">
        When someone buys your design as a poster or canvas, you get a clear cut
        from the profit.
      </FeatureText>
      <Typography
        variant="h4"
        sx={{
          mt: 2,
          fontSize: { xs: "1.2rem", sm: "1.5rem", md: "2rem" },
        }}
      >
        Start your journey with us today and turn your creativity into income!
      </Typography>
      <ButtonContainer>
        <ButtonCustom
          label={"Apply Now"}
          onClick={() => navigate("/register")}
        />
      </ButtonContainer>
    </EarnWithUsContainer>
  );
};
