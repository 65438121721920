import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import {
  CartProductDetailsContainerStyle,
  itemBoxStyle,
  itemBoxStyleSM,
} from "../../../utils/commonStyles";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { PriceWithDiscountLabel } from "../../atoms";
import { ProductImagesDetails } from "../ProductImagesAndDetails";

const CartProductDetailsContainer = styled(Box)(({ theme }) => ({
  width: "80%",
  margin: "100px auto",
  display: "flex",
  alignItems: "center",
  height: "150px",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    display: "block",
    height: "300px",
    margin: "80px auto",
  },
}));

const ItemBox = styled(Box)(({ theme }) => ({
  ...itemBoxStyle,
  [theme.breakpoints.down("md")]: {
    ...itemBoxStyleSM,
  },
}));

const ProductQuantityContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: "8px 5px",
  gap: "20px",
  [theme.breakpoints.down("sm")]: {
    gap: "10px",
  },
}));

const ClickableIcon = styled(Box)(({ theme }) => ({
  cursor: "pointer",
}));


const ResponsiveBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  [theme.breakpoints.down("sm")]: {
    flexDirection:"column-reverse"
  },
}));

export const CartItemDetails = ({
  name,
  originalPrice,
  price,
  size,
  quantity,
  discountedPrice,
  image,
  increase,
  decrease,
  deletePost,
  ...props
}) => {
  return (
    <CartProductDetailsContainer {...props}>
      <ProductImagesDetails image={image} name={name} size={size} />

      <ResponsiveBox>
        <ItemBox>
          <PriceWithDiscountLabel
            originalPrice={originalPrice}
            discountedPrice={discountedPrice}
          />
        </ItemBox>

        <ItemBox>
          <ProductQuantityContainer>
            <ClickableIcon onClick={decrease}>
              <RemoveIcon />
            </ClickableIcon>
            <Typography variant="h5">{quantity}</Typography>
            <ClickableIcon onClick={increase}>
              <AddIcon />
            </ClickableIcon>
          </ProductQuantityContainer>
        </ItemBox>

        <ItemBox>
          <Typography variant="h5">Rs {price}</Typography>
        </ItemBox>

        <ItemBox sx={{ justifyContent: "end" }}>
          <ClickableIcon onClick={deletePost}>
            <DeleteForeverIcon />
          </ClickableIcon>
        </ItemBox>
      </ResponsiveBox>
    </CartProductDetailsContainer>
  );
};
