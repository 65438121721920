import { mockups } from "../../assets";

export const verticalMockupData = [
  {
    mockupImage: mockups.vertical.vertical_2,
    OverlayElement: {
      top: "25%",
      right: "10%",
      transform: "translate(-10%, -25%)",
      width: "30%",
      height: "57%",
    },
  },
  {
    mockupImage: mockups.vertical.vertical_3,
    OverlayElement: {
      top: "30%",
      left: "50%",
      transform: "translate(-50%, -35%)",
      width: "32%",
      height: "55%",
    },
  },
  {
    mockupImage: mockups.vertical.vertical_4,
    OverlayElement: {
      top: "40%",
      left: "50%",
      transform: "translate(-50%, -38.5%)",
      width: "40%",
      height: "70%",
    },
  },
  {
    mockupImage: mockups.vertical.vertical_5,
    OverlayElement: {
      top: "18%",
      right: "16%",
      transform: "translate(-16%, -20%)",
      width: "30%",
      height: "50%",
    },
  },
  {
    mockupImage: mockups.vertical.vertical_1,
    OverlayElement: {
      top: "60%",
      left: "50%",
      transform: "translate(-50%, -60%)",
      width: "30%",
      height: "50%",
    },
  },
];

export const horizontalMockupData = [
  {
    mockupImage: mockups.horizontal.horizontal_1,
    OverlayElement: {
      top: "18%",
      left: "52%",
      transform: "translate(-52%, -20%)",
      width: "52%",
      height: "40%",
    },
  },
  {
    mockupImage: mockups.horizontal.horizontal_2,
    OverlayElement: {
      top: "5%",
      left: "45%",
      transform: "translate(-45%, -5%)",
      width: "64%",
      height: "37%",
    },
  },
  {
    mockupImage: mockups.horizontal.horizontal_3,
    OverlayElement: {
      top: "25%",
      left: "50%",
      transform: "translate(-50%, -35%)",
      width: "55%",
      height: "40%",
    },
  },
  {
    mockupImage: mockups.horizontal.horizontal_4,
    OverlayElement: {
      top: "10%",
      left: "50%",
      transform: "translate(-50%, -10%)",
      width: "30%",
      height: "25%",
    },
  },
  {
    mockupImage: mockups.vertical.vertical_5,
    OverlayElement: {
      top: "20%",
      right: "2%",
      transform: "translate(-2%, -20%)",
      width: "52%",
      height: "45%",
    },
  },
];

export const verticalPoster = [
  {
    mockupImage: mockups.posterVertical.vertical_1,
    OverlayElement: {
      top: "22%",
      right: "4%",
      transform: "translate(-5%, -22%)",
      width: "38%",
      height: "75%",
    },
  },
  {
    mockupImage: mockups.posterVertical.vertical_1,
    OverlayElement: {
      top: "22%",
      right: "4%",
      transform: "translate(-5%, -22%)",
      width: "38%",
      height: "75%",
    },
  },
];

export const horizontalPoster = [
  {
    mockupImage: mockups.posterHorizontal.horizontal_1,
    OverlayElement: {
      top: "33%",
      left: "52%",
      transform: "translate(-52%, -32%)",
      width: "70%",
      height: "70%",
    },
  },
  {
    mockupImage: mockups.posterHorizontal.horizontal_1,
    OverlayElement: {
      top: "33%",
      left: "52%",
      transform: "translate(-52%, -32%)",
      width: "70%",
      height: "70%",
    },
  },
];
