import React from "react";
import { PrivacyPoliciesTemplate } from "../../../Template";
import { Footer, Navbar } from "../../../organisms";
import { ScrollOnTop } from "../../../wrapper";

export const PrivacyPolicies = () => {
  return (
    <ScrollOnTop>
      <Navbar />
      <PrivacyPoliciesTemplate />
      <Footer />
    </ScrollOnTop>
  );
};
