import React from "react";
import { Box, styled, Typography } from "@mui/material";

const ContentBox = styled(Box)(({ theme }) => ({
  minHeight: "100vh",
  padding: "20px",
  width: "60%",
  margin: "20px auto",
  [theme.breakpoints.down("sm")]: {
    width: "80%",
  },
}));

const ListItemBoxForDetails = styled(Box)({
  marginBottom: "20px",
});

export const PrivacyPoliciesTemplate = () => {
  return (
    <ContentBox>
      <Typography variant="h1" fontWeight="500" margin={"50px 0px"}>
        Privacy Policies
      </Typography>

      <ListItemBoxForDetails>
        <Typography variant="h4" fontWeight="500" margin>
          How We Use Your Information
        </Typography>
        <Typography variant="h6" margin>
          When you make a purchase from our store, we collect personal
          information you provide, including your name, address, and email
          address, as part of the transaction process.
        </Typography>
      </ListItemBoxForDetails>

      <ListItemBoxForDetails>
        <Typography variant="h4" fontWeight="500" margin>
          Disclosure
        </Typography>
        <Typography variant="h6" margin>
          We may disclose your personal information if required by law or in the
          event that you violate our Terms of Service.
        </Typography>
      </ListItemBoxForDetails>

      <ListItemBoxForDetails>
        <Typography variant="h4" fontWeight="500" margin>
          Changes to This Privacy Policies
        </Typography>
        <Typography variant="h6" margin>
          We may update this Privacy Policies periodically to reflect changes in
          our practices or for operational, legal, or regulatory reasons. When
          we make changes, we will post the revised Privacy Policies on our
          website and update the "Last updated" date. We will also take any
          additional steps required by applicable law to ensure you are informed
          of these changes.
        </Typography>
      </ListItemBoxForDetails>

      <ListItemBoxForDetails>
        <Typography variant="h4" fontWeight="500" margin>
          Third Party Websites and Links
        </Typography>
        <Typography variant="h6" margin>
          Our site, krince.in, may contain links to third-party websites or
          online platforms that are not operated or controlled by us. If you
          follow these links, please review the privacy and security policies,
          as well as the terms and conditions of those third-party sites. We do
          not guarantee and are not responsible for the privacy or security
          practices of these external sites, nor for the accuracy, completeness,
          or reliability of any information they may contain.
        </Typography>
        <Typography variant="h6" margin>
          Information you share on public or semi-public platforms, including
          third-party social networking sites, may be accessible to other users
          and may be used by those third parties without restriction. The
          presence of such links on krince.in does not imply endorsement of the
          content or the operators of these platforms, except as explicitly
          stated in our services.
        </Typography>
      </ListItemBoxForDetails>

      <ListItemBoxForDetails>
        <Typography variant="h4" fontWeight="500" margin>
          Children’s Data
        </Typography>
        <Typography variant="h6" margin>
          The services provided by krince.in are not intended for use by
          children, and we do not knowingly collect any personal information
          from them. If you are a parent or guardian and believe that your child
          has provided us with personal information, please contact us using the
          details below to request its deletion.
        </Typography>
        <Typography variant="h6" margin>
          As of the Effective Date of this Privacy Policies, we do not have actual
          knowledge that we “share” or “sell” (as those terms are defined by
          applicable law) the personal information of individuals under 16 years
          of age.
        </Typography>
      </ListItemBoxForDetails>

      <ListItemBoxForDetails>
        <Typography variant="h4" fontWeight="500" margin>
          Security and Retention of Your Information
        </Typography>
        <Typography variant="h6" margin>
          Please be aware that no security measures are entirely foolproof, and
          we cannot guarantee “perfect security.” Additionally, any information
          you send to us may not be secure during transmission. We recommend
          avoiding unsecure channels for transmitting sensitive or confidential
          information.
        </Typography>
        <Typography variant="h6" margin>
          The duration for which we retain your personal information varies
          based on several factors, including the necessity to maintain your
          account, provide our services, comply with legal obligations, resolve
          disputes, or enforce other applicable contracts and policies.
        </Typography>
      </ListItemBoxForDetails>

      <ListItemBoxForDetails>
        <Typography variant="h4" fontWeight="500" margin>
          Complaints
        </Typography>
        <Typography variant="h6" margin>
          If you have any complaints about how we process your personal
          information, please contact us using the contact details provided
          below. If you are not satisfied with our response to your complaint,
          you may have the right to appeal our decision based on your location.
          You can contact us using the details provided, or you may lodge your
          complaint with your local data protection authority.
        </Typography>

        <Typography variant="h6" margin>
          For any issues related to krince.in, please use the following contact
          details:
        </Typography>

        <Typography variant="h6" fontWeight="500" margin>
          Phone: +91 7828749578
        </Typography>

        <Typography variant="h6" fontWeight="500" margin>
          Email: support@krince.in
        </Typography>
      </ListItemBoxForDetails>

      <ListItemBoxForDetails>
        <Typography variant="h4" fontWeight="500" margin>
          Platform.
        </Typography>
        <Typography variant="h6" margin>
          Our website is built using React.js, providing a dynamic and
          responsive e-commerce experience for selling canvas, posters, and
          other products. We utilize modern web technologies to ensure a
          seamless shopping experience for our customers.
        </Typography>
      </ListItemBoxForDetails>

      <ListItemBoxForDetails>
        <Typography variant="h4" fontWeight="500" margin>
          Questions and Contact Information
        </Typography>
        <Typography variant="h6" margin>
          Should you have any questions about our privacy practices or this
          Privacy Policies, or if you would like to exercise any of the rights
          available to you, please contact us at:
        </Typography>
        <Typography variant="h6" fontWeight="500" margin>
          Phone: +91 7828749578
        </Typography>

        <Typography variant="h6" fontWeight="500" margin>
          Email: support@krince.in
        </Typography>
      </ListItemBoxForDetails>
    </ContentBox>
  );
};
