export const endpoints = {
  auth: {
    login: "/login",
  },
  main: {
    genre: "/genre",
    homePage: "/home",
    postByID: "/post",
    similarPost: "/similar_post",
    createOrder: "/order_create",
    sizeList: "/size_list",
    postByGenre: "/post_by_genre",
    order: "/order",
    contactUs:"/contact_us"
  },
  admin: {
    createPost: "/post_create",
    showAllPost: "/show_all_post",
    uploadSingleMedia: "/upload_single_file",
    uploadmultiplMedia: "/upload_multiple_files",
  },
};
