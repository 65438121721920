import { FormGroup, TextField } from "@mui/material";
import React from "react";

export const InputField = ({
  fullWidth,
  label,
  name,
  value,
  onChange,
  placeholder,
  ...props
}) => {
  return (
    <FormGroup>
      <TextField
        fullWidth={fullWidth}
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        {...props}
      />
    </FormGroup>
  );
};
