import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import {
  CartItemDetails,
  CartItemDetailsHeader,
  EmpityBasket,
  GrandTotalPrice,
} from "../../../molicules";
import { CartProductDetailsContainerStyle } from "../../../../utils/commonStyles";
import { useSelector, useDispatch } from "react-redux";
import { setCartProduct } from "../../../../redux/reducer/main";

const CartMainContainer = styled(Box)(({ theme }) => ({
  padding: "50px 8%",
  [theme.breakpoints.down("sm")]: {
    padding: "20px 5%",
  },
}));

const CartHeadingContainer = styled(Box)(({ theme }) => ({
  ...CartProductDetailsContainerStyle,
  [theme.breakpoints.down("sm")]: {
    textAlign: "center",
  },
}));

const CartLine = styled(Box)(({ theme }) => ({
  width: "80%",
  margin: "20px auto",
  height: 1,
  background: "#252525",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

export const CartTemplate = () => {
  const navigate = useNavigate();
  const { cartProducts, totalPrice } = useSelector(
    (state) => state.cartDetails
  );
  const dispatch = useDispatch();

  const handleNavigate = (route) => {
    navigate(route);
  };

  const incrementQuantity = (index) => {
    let data = [...cartProducts];
    let current_post = data[index];

    let updatePost = {
      ...current_post,
      quantity: current_post.quantity + 1,
      price: current_post.price + current_post.discountedPrice,
    };
    data.splice(index, 1, updatePost);
    dispatch(setCartProduct(data));
  };

  const decrementQuantity = (index) => {
    let data = [...cartProducts];
    let current_post = data[index];

    if (current_post.quantity === 1) {
      data.splice(index, 1);
    } else {
      let updatePost = {
        ...current_post,
        quantity: current_post.quantity - 1,
        price: current_post.price - current_post.discountedPrice,
      };
      data.splice(index, 1, updatePost);
    }
    dispatch(setCartProduct(data));
  };

  const deletePost = (index) => {
    let data = [...cartProducts];
    data.splice(index, 1);
    dispatch(setCartProduct(data));
  };

  return (
    <CartMainContainer>
      {cartProducts.length >= 1 ? (
        <>
          <CartHeadingContainer>
            <Typography variant="h2">Artwork Basket</Typography>
          </CartHeadingContainer>

          <CartItemDetailsHeader />

          {cartProducts.map((item, index) => (
            <CartItemDetails
              name={item.name}
              originalPrice={item.originalPrice}
              discountedPrice={item.discountedPrice}
              price={item.price}
              size={item.size}
              quantity={item.quantity}
              image={item.image}
              increase={() => incrementQuantity(index)}
              decrease={() => decrementQuantity(index)}
              deletePost={() => deletePost(index)}
              key={index}
            />
          ))}

          <CartLine />
          <GrandTotalPrice totalPrice={totalPrice} />
        </>
      ) : (
        <EmpityBasket onClick={() => handleNavigate("/")} />
      )}
    </CartMainContainer>
  );
};
