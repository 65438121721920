import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { FullViewComponent } from "../.././../molicules";
import { getAllPost } from "../../../../utils/api/apiCall";
import { useSelector, useDispatch } from "react-redux";
import {
  setIsLoading_AllPost,
  setAllPost,
} from "../../../../redux/reducer/admin";
import { Image } from "../../../atoms";
import moment from "moment";

export const AllPostsListTemplate = () => {
  //State
  const { isLoading, allPost } = useSelector((state) => state.allPostForAdmin);
  const dispatch = useDispatch();

  const fetchALLPost = async () => {
    dispatch(setIsLoading_AllPost(true));
    let data = await getAllPost();
    if (data.success) {
      console.log(data.result);
      dispatch(setAllPost(data.result));
      dispatch(setIsLoading_AllPost(false));
    }
  };

  useEffect(() => {
    fetchALLPost();
  }, []);

  return (
    <Box style={{ width: "80%", margin: "20px auto" }}>
      <Typography>All post</Typography>
      {isLoading ? (
        <FullViewComponent />
      ) : (
        <Box>
          {allPost.map((item, index) => {
            return (
              <Box
                style={{
                  border: "2px solid #252525",
                  padding: "5px",
                  margin: "10px auto",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box style={{ width: "10%" }}>
                  <Image
                    src={item.thumbnail}
                    width={"100px"}
                    height={"100px"}
                    boxShadow={true}
                    margin={"10px"}
                  />
                </Box>

                <Box
                  style={{
                    width: "40%",
                    padding: "10px 0px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>
                    Create at : {moment(item.createdAt).format("DD MM YYYY")}
                  </Typography>

                  <Typography>
                    Rotation type : {item.imageRotationType}
                  </Typography>

                  <Typography>genre : {item.genre}</Typography>

                  <Typography>Print type : {item.paperType}</Typography>
                </Box>

                <Box
                  style={{
                    width: "40%",
                    padding: "10px 0px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>Sales : {item.sales.length}</Typography>

                  <Typography>Views : {item.views.length}</Typography>
                </Box>
              </Box>
            );
          })}
        </Box>
      )}
    </Box>
  );
};
