import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { Image, PriceWithDiscountLabel } from "../../atoms";

const ProductCardWrapper = styled(Box)(({ theme }) => ({
  height: "320px",
  margin: "16px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  cursor: "pointer",
  transition: "box-shadow 0.3s ease, padding 0.3s ease",
  boxSizing: "border-box",
  "&:hover": {
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)",
    padding: "5px",
  },
  [theme.breakpoints.down("md")]: {
    height: "280px",
  },
  [theme.breakpoints.down("sm")]: {
    height: "240px",
  },
}));

const ProductImageContainer = styled("div")({
  width: "100%",
  height: "75%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const CardContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems:"center",
  flexDirection:"column",
  padding: "8px",
  textAlign:"center",
});

export const ProductCard = ({
  imageName,
  onClick,
  paperType,
  isMultiple,
  image_quantity,
  name
}) => {
  return (
    <ProductCardWrapper onClick={onClick}>
      <ProductImageContainer>
        <Image
          src={imageName}
          alt={imageName}
          style={{ width: "100%", height: "100%", objectFit: "contain" }}
        />
      </ProductImageContainer>
      <CardContainer>
        <Typography variant="body2" margin>{name}</Typography>
        <PriceWithDiscountLabel
          originalPrice={
            paperType === "Poster"
              ? isMultiple
                ? image_quantity * 99
                : 99
              : 999
          }
          discountedPrice={
            paperType === "Poster"
              ? isMultiple
                ? image_quantity * 49
                : 49
              : 499
          }
        />
      </CardContainer>
    </ProductCardWrapper>
  );
};
