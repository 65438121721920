import { Box, styled, Typography, useTheme } from "@mui/material";
import React from "react";
import { ButtonCustom, Image, RatingCustom } from "../../atoms";
import { srk } from "../../../assets";

const BestSellContainer = styled(Box)(({ theme }) => ({
  width: "80%",
  padding: "20px",
  margin: "0 auto",
  [theme.breakpoints.down("md")]: {
    padding: "80px 8%",
  },
}));

const ReviewBox = styled(Box)(({ theme }) => ({
  height: "300px",
  display: "flex",
  padding: "20px",
  margin: "20px 0px",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    height: "auto",
  },
}));

const ImageBox = styled(Box)(({ theme }) => ({
  width: "30%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    height: "200px",
  },
}));

const ContentBox = styled(Box)(({ theme }) => ({
  width: "70%",
  height: "100%",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    height: "auto",
    margin:"20px 0px"
  },
}));

const ReadMore = styled(Box)(({ theme }) => ({
  width: "20%",
  margin: "0px auto",
  [theme.breakpoints.down("md")]: {
    width: "80%",
  },
}));

export const RatingContainer = () => {
  const theme = useTheme();

  return (
    <BestSellContainer>
      <Typography variant="h1">Hear From Our Community</Typography>

      <ReviewBox>
        <ImageBox>
          <Image src={srk} height={"100%"} boxShadow={true} margin={"20px"} />
        </ImageBox>
        <ContentBox>
          <RatingCustom
            name="simple-controlled"
            value={4}
            readOnly
            color={theme.palette.primary.main}
          />
          <Typography component="legend">{4} Stars</Typography>
          <Typography component="legend">
            Awesome piece, makes a statement and when a guest at home see's it
            it turns into a conversation
          </Typography>
        </ContentBox>
      </ReviewBox>

      <ReadMore>
        <ButtonCustom label={"read more"} />
      </ReadMore>
    </BestSellContainer>
  );
};
