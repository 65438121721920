import React from "react";
import { Navbar, Footer } from "../../../organisms";
import { CreatePostTemplate } from "../../../Template";

export const PostCreate = () => {
  return (
    <>
      <Navbar />
      <CreatePostTemplate />
      <Footer />
    </>
  );
};
