import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { ButtonCustom } from "../../atoms";

const SizeChartWrapper = styled(Box)({
  display: "flex",
  flexWrap: "wrap",
  gap: "20px",
});

export const SizeChart = ({
  handlePaperType,
  paperType,
  paperTypeData,
  sizeChartList,
  handleSelectSize,
  activeSizeBTN,
}) => {
  return (
    <>
      <SizeChartWrapper>
        {paperTypeData.map((item, index) => {
          return (
            <ButtonCustom
              key={index}
              label={item.name}
              margin={"10px"}
              active={paperType === item.name}
              onClick={() => handlePaperType(item.name, item._id)}
            />
          );
        })}
      </SizeChartWrapper>
      <Typography variant="body1">All dimensions are in inches.</Typography>
      <SizeChartWrapper>
        {sizeChartList.map((item, index) => {
          return (
            <ButtonCustom
              key={index}
              active={index === activeSizeBTN}
              label={item?.size}
              display={"inline-flex"}
              onClick={() =>
                handleSelectSize(item?.price, item?.discount, index)
              }
            />
          );
        })}
      </SizeChartWrapper>
    </>
  );
};
