import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";

const FormWrapper = styled(Box)({
  width: "80%",
});

export const Form = ({ label ,children}) => {
  return (
    <FormWrapper>
      <Typography variant="h4" gutterBottom>
        {label}
      </Typography>
      {children}
    </FormWrapper>
  );
};
