import React from "react";
import Slider from "react-slick";
import { styled } from "@mui/material/styles";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  horizontalMockupData,
  horizontalPoster,
  verticalMockupData,
  verticalPoster,
} from "../../../utils/staticData";
import { OverLayMockup } from "../overLayMockup";
import { Image } from "../../atoms";

const StyledSlider = styled(Slider)(({ theme }) => ({
  width: "100%",
  ".slick-dots": {
    position: "absolute",
    bottom: 10,
    left: "50%",
    transform: "translateX(-50%)",
    "& li button": {
      backgroundColor: "white",
      width: "10px",
      height: "10px",
      borderRadius: "50%",
      padding: "0px",
      margin: "0px",
    },
    "& li.slick-active button": {
      backgroundColor: "white",
    },
  },
  ".slick-dots.slick-thumb": {
    display: "flex !important",
    justifyContent: "center",
    padding: "0",
    listStyle: "none",

    li: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: "0 20px !important",
    },
  },
}));

export const ProductImageSlider = ({ paperType, rotationType, image }) => {
  const settings = {
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true, // Show dots on medium screens
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false, // Hide dots on smaller screens
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false, // Ensure dots are hidden on the smallest screens
        },
      },
    ],
  };

  let baseData =
    rotationType === "vertical" ? verticalMockupData : horizontalMockupData;

  let paperTypeData =
    rotationType === "vertical" ? verticalPoster : horizontalPoster;

  let data = paperType === "Poster" ? paperTypeData : baseData;

  return (
    <StyledSlider {...settings}>
      {data.map((item, index) => {
        const { mockupImage, OverlayElement } = item;
        return (
          <>
            {paperType === "Poster" ? (
              <Image
                src={image}
                width={"100%"}
                height={"100%"}
                boxShadow={true}
              />
            ) : (
              <OverLayMockup
                index={index}
                mockupImage={mockupImage}
                OverlayElement={OverlayElement}
                image={image}
              />
            )}
          </>
        );
      })}
    </StyledSlider>
  );
};
