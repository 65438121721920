import React from "react";
import { Image } from "../../atoms";
import { Box } from "@mui/material";

export const Banner = ({ src, alt }) => {
  return (
    <Box
      style={{
        margin: "50px 0px",
        boxShadow: "0px 0px 20px 0px rgba(0,0,0,0.5)",
      }}
    >
      <Image
        src={src}
        alt={alt}
        width={"100%"}
        height={"100%"}
        objectFit="cover"
      />
    </Box>
  );
};
