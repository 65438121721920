import React, { useEffect, useState } from "react";
import { CategoryProduct } from "../../../organisms";
import { useParams } from "react-router-dom";
import { main } from "../../../../utils/api/apiCall";
//Redux
import { useDispatch, useSelector } from "react-redux";
import {
  setGenre,
  setIsLoading_Genre,
} from "../../../../redux/reducer/main/genreReducer";
import { setNotification } from "../../../../redux/reducer/commonReducer";
import { FullViewComponent } from "../../../molicules";
import { Box, styled } from "@mui/material";
import { ButtonCustom } from "../../../atoms";

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  margin: "20px auto",
  width: "80%",
  [theme.breakpoints.down("md")]: {
    width: "80%",
    justifyContent: "space-between",
  },
}));

const ButtonBox = styled(Box)(({ theme, width, marginRight }) => ({
  width: width || "auto",
  marginRight: marginRight || "0",
  [theme.breakpoints.down("md")]: {
    width: "80%",
  },
}));

const ContentBox = styled(Box)({
  minHeight: "100vh",
});

export const GenreTemplate = () => {
  const { id } = useParams();
  const [activeFilter, setActiveFilter] = useState(null);

  ////State
  const dispatch = useDispatch();
  const { isLoading, genrePost } = useSelector((state) => state.genreDetails);

  const fetchPostByGenre = async () => {
    dispatch(setIsLoading_Genre(true));
    try {
      let response = await main.postByGenre(id);
      dispatch(setGenre(response.result));
      setActiveFilter(null);
      dispatch(setIsLoading_Genre(false));
    } catch (error) {
      dispatch(
        setNotification({
          visible: true,
          message: error,
          status: "error",
        })
      );
      dispatch(setGenre([]));
      dispatch(setIsLoading_Genre(false));
    }
  };

  useEffect(() => {
    fetchPostByGenre();
  }, [id]);

  const handleButtonClick = (filter) => {
    setActiveFilter(filter);
  };

  const filteredData = genrePost.filter((item) =>
    activeFilter ? item.paperType === activeFilter : true
  );

  return (
    <div>
      {isLoading ? (
        <FullViewComponent />
      ) : (
        <ContentBox>
          <ButtonContainer>
            <ButtonBox width="15%" marginRight="10px">
              <ButtonCustom
                label={"Canvas"}
                active={activeFilter === "Canvas"}
                onClick={() => handleButtonClick("Canvas")}
              />
            </ButtonBox>
            <ButtonBox width="15%">
              <ButtonCustom
                label={"Poster"}
                active={activeFilter === "Poster"}
                onClick={() => handleButtonClick("Poster")}
              />
            </ButtonBox>
          </ButtonContainer>

          <CategoryProduct productData={filteredData} label={id} />
        </ContentBox>
      )}
    </div>
  );
};
