import React from "react";
import { Box, styled, Typography } from "@mui/material";

const ContentBox = styled(Box)(({ theme }) => ({
  minHeight: "100vh",
  padding: "20px",
  width: "60%",
  margin: "20px auto",
  [theme.breakpoints.down("sm")]: {
    width: "80%",
  },
}));

const ListItemBoxForDetails = styled(Box)({
  marginBottom: "20px",
});

export const ShippingPoliciesTemplate = () => {
  return (
    <ContentBox>
      <Typography variant="h1" fontWeight="500" margin={"50px 0px"}>
        Shipping Policies
      </Typography>

      <ListItemBoxForDetails>
        <Typography variant="h4" fontWeight={"500"} margin>
          Delivery Time
        </Typography>
        <Typography variant="h6" margin>
          Orders typically arrive within 4 to 9 days. Delivery times may vary;
          we appreciate your patience during this period.
        </Typography>
      </ListItemBoxForDetails>

      <ListItemBoxForDetails>
        <Typography variant="h4" fontWeight={"500"} margin>
          Shipping
        </Typography>
        <Typography variant="h6" margin>
          Free shipping is included on all orders. No extra fees for delivery,
          ensuring a smooth and cost-free shopping experience.
        </Typography>
      </ListItemBoxForDetails>

      <ListItemBoxForDetails>
        <Typography variant="h4" fontWeight={"500"} margin>
          Printing Process
        </Typography>
        <Typography variant="h6" margin>
          All items are printed on demand. We don’t pre-print or stock, allowing
          for custom, fresh products tailored just for you.
        </Typography>
      </ListItemBoxForDetails>

      <ListItemBoxForDetails>
        <Typography variant="h4" fontWeight={"500"} margin>
          Inventory
        </Typography>
        <Typography variant="h6" margin>
          Expect a slight delay due to our print-on-demand process. We don’t
          hold inventory, so your patience is appreciated.
        </Typography>
      </ListItemBoxForDetails>

      <ListItemBoxForDetails>
        <Typography variant="h4" fontWeight={"500"} margin>
          Order Processing
        </Typography>
        <Typography variant="h6" margin>
          Most orders are delivered within 4 to 9 days. Timely processing and
          shipping are our priorities. Thank you for your patience.
        </Typography>
      </ListItemBoxForDetails>
    </ContentBox>
  );
};
