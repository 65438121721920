import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { itemBoxStyle, itemBoxStyleSM } from "../../../utils/commonStyles";

const ItemBox = styled(Box)(({ theme }) => ({
  ...itemBoxStyle,
  [theme.breakpoints.down("sm")]: {
    ...itemBoxStyleSM,
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  margin: "10px",
  padding: "10px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
  [theme.breakpoints.down("sm")]: {
    padding: "10px 50px",
  },
}));

const ProductImage = styled("img")(({ theme }) => ({
  width: "70px",
  height: "90px",
  objectFit: "contain",
  [theme.breakpoints.down("sm")]: {
    width: "100px",
  },
}));

const ProductDetailsBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  [theme.breakpoints.down("sm")]: {
    marginTop: "10px",
  },
}));

const CenteredTypography = styled(Typography)({
  margin: "5px auto",
});

export const ProductImagesDetails = ({ image, name, size }) => {
  return (
    <ItemBox>
      <StyledBox>
        <ProductImage src={image} alt={name} />
        <ProductDetailsBox>
          <CenteredTypography>{name}</CenteredTypography>
          <CenteredTypography>Size: {size}</CenteredTypography>
        </ProductDetailsBox>
      </StyledBox>
    </ItemBox>
  );
};
