import React from "react";
import { Box, styled, Typography } from "@mui/material";

const ContentBox = styled(Box)(({ theme }) => ({
  minHeight: "100vh",
  padding: "20px",
  width: "60%",
  margin: "20px auto",
  [theme.breakpoints.down("sm")]: {
    width: "80%",
  },
}));

const ListItemBoxForDetails = styled(Box)({
  marginBottom: "20px",
});

export const TermAndConditionTemplate = () => {
  return (
    <ContentBox>
      <Typography variant="h1" fontWeight="500" margin={"50px 0px"}>
        Term And Condition
      </Typography>

      <ListItemBoxForDetails>
        <Typography variant="h4" fontWeight={"500"} margin>
          Introduction{" "}
        </Typography>
        <Typography variant="h6" margin>
          krince.in operates this website. Throughout the site, the terms “we,”
          “us,” and “our” refer to krince.in. By accessing and using this
          website, including all information, tools, and services available
          here, you agree to comply with and be bound by all terms, conditions,
          policies, and notices provided.
        </Typography>
      </ListItemBoxForDetails>

      <ListItemBoxForDetails>
        <Typography variant="h4" fontWeight={"500"} margin>
          Use of the Website{" "}
        </Typography>
        <Typography variant="h6" margin>
          You must use krince.in only for lawful purposes and in a way that does
          not infringe the rights of, or restrict or inhibit anyone else’s use
          and enjoyment of the site. Prohibited behavior includes, but is not
          limited to, harassment, the distribution of harmful software, or
          engaging in fraudulent activities.
        </Typography>
      </ListItemBoxForDetails>

      <ListItemBoxForDetails>
        <Typography variant="h4" fontWeight={"500"} margin>
          Product Information
        </Typography>
        <Typography variant="h6" margin>
          We make every effort to ensure that the information on krince.in is
          accurate and up-to-date. However, we do not warrant that the product
          descriptions, pricing, or other content is error-free or complete. We
          reserve the right to correct any errors and make changes to product
          information without prior notice.
        </Typography>
      </ListItemBoxForDetails>

      <ListItemBoxForDetails>
        <Typography variant="h4" fontWeight={"500"} margin>
          Orders and Payments
        </Typography>

        <Typography variant="h5" fontWeight="400" margin>
          By placing an order on krince.in, you agree to purchase the products
          subject to the following conditions:
        </Typography>

        <Typography variant="h6" fontWeight="400" margin>
          - Orders are subject to availability and confirmation of the order
          price.
        </Typography>

        <Typography variant="h6" fontWeight="400" margin>
          - We accept payment via the methods listed on our website. All payment
          transactions are processed securely.
        </Typography>

        <Typography variant="h6" fontWeight="400" margin>
          - Prices and availability of products are subject to change without
          notice.
        </Typography>
      </ListItemBoxForDetails>

      <ListItemBoxForDetails>
        <Typography variant="h4" fontWeight={"500"} margin>
          Shipping and Delivery
        </Typography>
        <Typography variant="h6" margin>
          Orders typically arrive within 4 to 9 days. Delivery times may vary
          based on your location and other factors. We offer free shipping on
          all orders. For more details on shipping, please refer to our Shipping
          Policy.
        </Typography>
      </ListItemBoxForDetails>

      <ListItemBoxForDetails>
        <Typography variant="h4" fontWeight={"500"} margin>
          Returns and Refunds{" "}
        </Typography>
        <Typography variant="h6" margin>
          Please refer to our Return and Refund Policy for information on
          returns and refunds. We offer refunds for damaged or misprinted
          products and have specific conditions for processing returns.
        </Typography>
      </ListItemBoxForDetails>

      <ListItemBoxForDetails>
        <Typography variant="h4" fontWeight={"500"} margin>
          Intellectual Property{" "}
        </Typography>
        <Typography variant="h6" margin>
          All content on krince.in, including but not limited to text, graphics,
          logos, and images, is the property of krince.in or its licensors and
          is protected by intellectual property laws. You may not use,
          reproduce, or distribute any content without our prior written
          permission.{" "}
        </Typography>
      </ListItemBoxForDetails>

      <ListItemBoxForDetails>
        <Typography variant="h4" fontWeight={"500"} margin>
          Limitation of Liability{" "}
        </Typography>
        <Typography variant="h6" margin>
          To the fullest extent permitted by law, krince.in will not be liable
          for any indirect, incidental, or consequential damages arising out of
          or related to your use of our website or products. Our liability is
          limited to the maximum extent permitted by applicable law.{" "}
        </Typography>
      </ListItemBoxForDetails>

      <ListItemBoxForDetails>
        <Typography variant="h4" fontWeight={"500"} margin>
          Governing Law
        </Typography>
        <Typography variant="h6" margin>
          These Terms of Service, along with any separate agreements under which
          we provide you with services, shall be governed by and interpreted in
          accordance with the laws of India.
        </Typography>
      </ListItemBoxForDetails>

      <ListItemBoxForDetails>
        <Typography variant="h4" fontWeight={"500"} margin>
          Changes to Terms
        </Typography>
        <Typography variant="h6" margin>
          We may update these Terms and Conditions from time to time. Changes
          will be posted on this page, and the revised date will be updated.
          Your continued use of krince.in after any changes indicates your
          acceptance of the new terms.
        </Typography>
      </ListItemBoxForDetails>

      <ListItemBoxForDetails>
        <Typography variant="h4" fontWeight={"500"} margin>
          Contact Information
        </Typography>

        <Typography variant="h6" margin>
          For any questions or concerns regarding these Terms and Conditions,
          please contact us at:
        </Typography>
        <Typography variant="h6" fontWeight="500" margin>
          Phone: +91 7828749578
        </Typography>

        <Typography variant="h6" fontWeight="500" margin>
          Email: support@krince.in
        </Typography>
      </ListItemBoxForDetails>
    </ContentBox>
  );
};
