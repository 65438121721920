import React from "react";
import { CardContent, Typography } from "@mui/material";
import { styled } from "@mui/system";

const CardContainer = styled(CardContent)(({ theme }) => ({
  maxHeight: "15%",
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    padding: "0px !important",
  },
}));

const SpacerText = styled(Typography)(({ theme }) => ({
  margin: "0px 5px",
}));

export const PriceWithDiscountLabel = ({ originalPrice, discountedPrice }) => {
  return (
    <CardContainer>
      <Typography variant="body1" style={{ textDecoration: "line-through" }}>
        Rs {originalPrice}
      </Typography>
      <SpacerText variant="body1">From</SpacerText>
      <Typography variant="body1">Rs {discountedPrice}</Typography>
    </CardContainer>
  );
};
