import React, { useState, useEffect, useRef } from "react";
import { Box, Typography, Menu, MenuItem } from "@mui/material";
import { styled } from "@mui/system";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { cart, user } from "../../../assets";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setNavbarGenre } from "../../../redux/reducer/commonReducer";
import { main } from "../../../utils/api/apiCall";

const NavbarContainer = styled(Box)({
  width: "100%",
  backgroundColor: "#ffffff",
  padding: "10px 0",
});

const InnerContainer = styled(Box)(({ theme }) => ({
  width: "80%",
  margin: "0px auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "10px",
  [theme.breakpoints.down("md")]: {
    padding: "0 10px",
  },
}));

const FlexContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "20px",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const Icon = styled("img")(({ theme }) => ({
  height: "25px",
  cursor: "pointer",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const NavLink = styled(Typography)(({ theme }) => ({
  color: "#000000",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const ResponsiveNavLink = styled(Typography)(({ theme }) => ({
  color: "#000000",
  cursor: "pointer",
  display: "none",
  [theme.breakpoints.down("md")]: {
    display: "block",
  },
}));

const ResponsiveIcon = styled("img")(({ theme }) => ({
  display: "none",
  [theme.breakpoints.down("md")]: {
    width: "25px",
    height: "25px",
    cursor: "pointer",
    display: "block",
    position: "relative",
  },
}));

const HamburgerIcon = styled(MenuIcon)(({ theme }) => ({
  display: "none",
  cursor: "pointer",
  [theme.breakpoints.down("md")]: {
    display: "block",
  },
}));

const SideMenu = styled(Box)(({ theme, open }) => ({
  position: "fixed",
  top: 0,
  left: open ? 0 : "-250px",
  width: "250px",
  height: "100%",
  backgroundColor: "#ffffff",
  boxShadow: "2px 0px 5px rgba(0,0,0,0.5)",
  transition: "left 0.3s ease",
  zIndex: 1000,
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

const SideMenuContent = styled(Box)({
  display: "flex",
  flexDirection: "column",
  padding: "20px",
  gap: "20px",
});

const MobileIconsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "20px",
  marginTop: "20px",
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

const DesktopBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "20px",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    justifyContent: "space-between",
  },
}));

const badgetStyleObject = {
  position: "absolute",
  top: -10,
  right: -15,
};

const CartBadge = styled(Box)(({ theme }) => ({
  ...badgetStyleObject,
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const CartBadgeForResponsive = styled(Box)(({ theme }) => ({
  display: "none",
  ...badgetStyleObject,
  [theme.breakpoints.down("md")]: {
    display: "block",
  },
}));

export const Navbar = () => {
  let navigate = useNavigate();

  //State
  const dispatch = useDispatch();
  const { cartProducts } = useSelector((state) => state.cartDetails);
  const { navbarGenre } = useSelector((state) => state.commonDetails);

  const [menuOpen, setMenuOpen] = useState(false);
  const sideMenuRef = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [subMenuAnchorEl, setSubMenuAnchorEl] = useState(null);
  const [mobileSubMenuOpen, setMobileSubMenuOpen] = useState(false);
  const [mobileGenreMenuOpen, setMobileGenreMenuOpen] = useState(false);
  const [mobileSubMenu1Open, setMobileSubMenu1Open] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleClickOutside = (event) => {
    if (sideMenuRef.current && !sideMenuRef.current.contains(event.target)) {
      setMenuOpen(false);
    }
  };

  const fetchGenre = async () => {
    let response = await main.genre();
    dispatch(setNavbarGenre(response.result));
  };

  useEffect(() => {
    fetchGenre();
    if (menuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuOpen]);

  const handleNavigate = (route) => {
    navigate(route);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSubMenuAnchorEl(null);
  };

  const handleSubMenuOpen = (event, item) => {
    setSubMenuAnchorEl(event.currentTarget);
    handleMenuClose();
    handleNavigate(`/genre/${item.name}`);
  };

  const handleSubMenuClose = () => {
    setSubMenuAnchorEl(null);
  };

  const toggleMobileSubMenu = () => {
    setMobileSubMenuOpen(!mobileSubMenuOpen);
  };

  const toggleMobileGenreMenu = () => {
    setMobileGenreMenuOpen(!mobileGenreMenuOpen);
  };

  const toggleMobileSubMenu1 = () => {
    setMobileSubMenu1Open(!mobileSubMenu1Open);
  };

  return (
    <NavbarContainer>
      <InnerContainer>
        <FlexContainer>
          <NavLink onClick={() => handleNavigate("/")} variant="h5">
            Home
          </NavLink>
          <NavLink onClick={handleMenuOpen} variant="h5">
            Genre <ArrowDropDownIcon />
          </NavLink>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            keepMounted
          >
            {navbarGenre?.map((item, index) => {
              return (
                <MenuItem
                  key={index}
                  onClick={(event) => handleSubMenuOpen(event, item)}
                >
                  {item.name}
                </MenuItem>
              );
            })}
          </Menu>
          <NavLink onClick={() => handleNavigate("/trackorder")} variant="h5">
            Track Order
          </NavLink>
          <NavLink onClick={() => handleNavigate("/contactus")} variant="h5">
            Contact us
          </NavLink>
        </FlexContainer>

        <DesktopBox>
          {/* <Icon
            src={user}
            alt="Icon user"
            onClick={() => handleNavigate("/login")}
          /> */}
          <HamburgerIcon onClick={toggleMenu} />

          <Box style={{ position: "relative" }}>
            <Icon
              src={cart}
              alt="Shopping bag"
              onClick={() => handleNavigate("/addtocart")}
            />
            <CartBadge>{cartProducts.length}</CartBadge>
          </Box>

          <Box style={{ position: "relative" }}>
            <ResponsiveIcon
              src={cart}
              alt="Shopping bag"
              onClick={() => handleNavigate("/addtocart")}
            />
            <CartBadgeForResponsive>
              {cartProducts.length}
            </CartBadgeForResponsive>
          </Box>
        </DesktopBox>
      </InnerContainer>
      <SideMenu ref={sideMenuRef} open={menuOpen}>
        <SideMenuContent>
          <ResponsiveNavLink onClick={() => handleNavigate("/")}>
            Home
          </ResponsiveNavLink>
          <ResponsiveNavLink onClick={toggleMobileGenreMenu}>
            Genre <ArrowDropDownIcon />
          </ResponsiveNavLink>
          {mobileGenreMenuOpen && (
            <Box>
              {navbarGenre?.map((item, index) => {
                return (
                  <>
                    <ResponsiveNavLink
                      onClick={() => {
                        handleNavigate(`/genre/${item.name}`);
                        handleMenuClose();
                        toggleMenu();
                        toggleMobileSubMenu1();
                      }}
                      style={{
                        margin: "10px",
                      }}
                    >
                      {item.name}
                    </ResponsiveNavLink>
                  </>
                );
              })}
            </Box>
          )}
          <ResponsiveNavLink onClick={() => handleNavigate("/trackorder")}>
            Track Order
          </ResponsiveNavLink>
          <ResponsiveNavLink onClick={() => handleNavigate("/contactus")}>
            Contact us
          </ResponsiveNavLink>

          {/* <MobileIconsContainer>
            <ResponsiveIcon
              src={user}
              alt="Icon user"
              onClick={() => handleNavigate("/login")}
            />
          </MobileIconsContainer> */}
        </SideMenuContent>
      </SideMenu>
    </NavbarContainer>
  );
};
