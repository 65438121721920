import React from "react";
import { EarnWithUsTemplate } from "../../../Template/mainTemplate";
import { AuthWrapper } from "../../../wrapper";

export const EarnWithUs = () => {
  return (
    <AuthWrapper>
      <EarnWithUsTemplate />
    </AuthWrapper>
  );
};
